import React from 'react'
import { IoStarSharp } from "react-icons/io5";
import { FaQuoteLeft } from "react-icons/fa";

const SingleTestimonial = ({ testiimg, name, desc, testialt, testititle }) => {
    return (
        <div>
            <section className='container top-spacing' id='testimonial'>
                <div className='row'>
                    <div className='col-lg-5 col-md-12 col-sm-12'>
                        <h4 className='short-head'>client testimonial</h4>
                        <h1 className='main-head'>find reviews from our customer</h1>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12'>
                    </div>
                    <div className='row testimonial-main'>
                        <div className='testimonial'>
                            <div id="carouselExampleControls" class="carousel slide">
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-md-4 test-img'>
                                                    <div className='testi-icon'>
                                                        <span><FaQuoteLeft /></span>
                                                    </div>
                                                    <img src={testiimg} alt={testialt} title={testititle}></img>
                                                </div>
                                                <div className='col-md-8 test-description'>
                                                    <div className='yellow-star-flex'>
                                                        <div className='yellow-star'>
                                                            <span><IoStarSharp /></span>
                                                            <span><IoStarSharp /></span>
                                                            <span><IoStarSharp /></span>
                                                            <span><IoStarSharp /></span>
                                                            <span><IoStarSharp /></span>
                                                        </div>
                                                        <span><h6>5.0</h6></span>
                                                    </div>
                                                    <p className='text-desciption'>{desc}</p>
                                                    <h3>{name}</h3>
                                                    <h6>client</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SingleTestimonial
