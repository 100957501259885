import React from 'react'
import Logo1 from "../../../Assests/Img/Logo1.webp";
import Logo2 from "../../../Assests/Img/Logo2.webp";
import Logo3 from "../../../Assests/Img/Logo3.webp";
import Logo4 from "../../../Assests/Img/Logo4.webp";
import Logo5 from "../../../Assests/Img/Logo5.webp";
import Logo6 from "../../../Assests/Img/Logo6.webp";
import Logo7 from "../../../Assests/Img/Logo7.webp";
import Logo8 from "../../../Assests/Img/Logo8.webp";
import Logo9 from "../../../Assests/Img/Logo9.webp";
const Logos = () => {
    const Logos = [
        { id: 'modal1', src: Logo1, imgSrc: Logo1, title: "Logo1" },
        { id: 'modal2', src: Logo2, imgSrc: Logo2, title: "Logo2" },
        { id: 'modal3', src: Logo3, imgSrc: Logo3, title: "Logo3" },
        { id: 'modal4', src: Logo4, imgSrc: Logo4, title: "Logo4" },
        { id: 'modal5', src: Logo5, imgSrc: Logo5, title: "Logo5" },
        { id: 'modal6', src: Logo6, imgSrc: Logo6, title: "Logo6" },
        { id: 'modal7', src: Logo7, imgSrc: Logo7, title: "Logo7" },
        { id: 'modal8', src: Logo8, imgSrc: Logo8, title: "Logo8" },
        { id: 'modal9', src: Logo9, imgSrc: Logo9, title: "Logo9" }
    ];
    return (
        <>
            <div className='container logosPage' id='logosPage'>
                <div className='top-spacing row'>
                    <div className='col-lg-7 col-md-12 col-sm-12'>
                        <h4 className='short-head'>OUR PORTFOLIO</h4>
                        <h1 className='main-head'>logo design portfolio</h1>
                    </div>
                </div>
                <div className='row'>
                    {
                        Logos.map((logo, id) => (
                            <div className='col-md-4 logo-box-inner'>
                                <div key={id} className='logo-box'>
                                    <img src={logo.src} title={logo.title}></img>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default Logos

