import React from 'react';
import "./Portfolio.scss";
import { Link } from 'react-router-dom';
import { HiArrowUpRight } from "react-icons/hi2";
import Suceed from "../../CommonComp/Suceed";
import Form from '../../CommonComp/Form';
import TopBanner from '../../CommonComp/TopBanner';
import Logos from './PortfolioTabs/Logos';
import Banner from "./PortfolioTabs/Banner";
import WebDevelopment from './PortfolioTabs/WebDevelopment';
import WesiteTamplate from './PortfolioTabs/WesiteTamplate';
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
import banner from "../../Assests/Img/banner-home.webp";

const Portfolio = () => {
    return (
        <>
            <div className='hiring' id='hiring'>
                {/* HIRING START */}
                <TopBanner
                    bannerTitle="PORTFOLIO"
                    bannerDesc="They are available right here right now without any costs. Just enjoy their wisdom and unleash their power whenever you need to."
                    bannerImg={banner}
                    bannerimageAlt="portfoliobanner"
                    bannerimageTitle="portfoliobanner"
                />
                {/* HIRING END */}
                {/* ----------------- */}
                {/* PORTFOLIO START */}
                <div className='portfolio-tab container' id='portfolio-tab'>
                    <ul class="nav nav-tabs" id="ex1" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link active"
                                id="ex2-tab-1"
                                data-bs-toggle="tab"
                                href="#ex2-tabs-1"
                                role="tab"
                                aria-controls="ex2-tabs-1"
                                aria-selected="true"
                            >Web Development</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link"
                                id="ex2-tab-2"
                                data-bs-toggle="tab"
                                href="#ex2-tabs-2"
                                role="tab"
                                aria-controls="ex2-tabs-2"
                                aria-selected="false"
                            >Website Templates</a
                            >
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link"
                                id="ex2-tab-3"
                                data-bs-toggle="tab"
                                href="#ex2-tabs-3"
                                role="tab"
                                aria-controls="ex2-tabs-3"
                                aria-selected="false"
                            >Logos</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link"
                                id="ex2-tab-4"
                                data-bs-toggle="tab"
                                href="#ex2-tabs-4"
                                role="tab"
                                aria-controls="ex2-tabs-4"
                                aria-selected="false"
                            >Banner</a>
                        </li>
                    </ul>
                    <div className='custom-tabs' id='custom-tabs'>
                        <div class="tab-content" id="ex2-content">
                            <div
                                class="tab-pane show active"
                                id="ex2-tabs-1"
                                role="tabpanel"
                                aria-labelledby="ex2-tab-1"
                            >
                                <WebDevelopment />
                            </div>
                            <div class="tab-pane" id="ex2-tabs-2" role="tabpanel" aria-labelledby="ex2-tab-2">
                                <WesiteTamplate />
                            </div>
                            <div class="tab-pane" id="ex2-tabs-3" role="tabpanel" aria-labelledby="ex2-tab-3">
                                <Logos />
                            </div>
                            <div class="tab-pane" id="ex2-tabs-4" role="tabpanel" aria-labelledby="ex2-tab-4">
                                <Banner />
                            </div>
                        </div>
                    </div>
                </div>
                {/* PORTFOLIO END */}
                {/* ------------------ */}
                {/* DIGITAL DREAMS START */}
                <section className='container top-spacing' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>we turn into digital dreams into realty</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                            <div className='btn'>
                                <Link to='/contact'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* DIGITAL DREAMS END */}
                {/* ---------------------- */}
                {/* FORM START */}
                <Form />
                {/* FORM END */}
                {/* ---------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default Portfolio
