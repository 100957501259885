import React from 'react';
import "./Common.scss";
import { HiArrowUpRight } from "react-icons/hi2";
import PhoneIcon from "../Assests/Img/phone-grp.webp";
import emailGrp from "../Assests/Img/email-grp.webp";
import mapicon from "../Assests/Img/map-icon.webp";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
const Form = () => {
    return (
        <>
            {/* GET IN TOUCH START */}
            <section id='get-in-touch' className='top-spacing'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 col-lg-6 col-sm-12'>
                            <h4 className='short-head'>let's work together</h4>
                            <h1 className='main-head'>get in touch with us</h1>
                        </div>
                    </div>
                    <div className='row get-in-touch-inner'>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <div className='black-box bg-img'>
                                <div>
                                    <h5><span><img src={PhoneIcon} alt='PhoneIcon' title='PhoneIcon'></img></span>contact us</h5>
                                    <p><a href='tel:+91 623 992 1226'>+91 623 992 1226</a></p>
                                    <p><a href='tel:+91 623 992 1229'>+91 623 992 1229</a></p>
                                </div>
                                <div className='for-top-spacing'>
                                    <h5><span><img src={emailGrp} alt='emailGrp' title='email-grp'></img></span>e-mail</h5>
                                    <p><a href='mailto:hr@jntwebsolutions.com' target='_blank'>hr@jntwebsolutions.com</a></p>
                                </div>
                                <div className='for-top-spacing'>
                                    <h5> <span><img src={mapicon} alt='map-icon' title='map-icon'></img></span>address</h5>
                                    <p><a href='https://maps.app.goo.gl/LvW7sN2wBcnduRAV9' target='_blank'>Plot No. D-160, Phase 8, Industrial Area, Sector 73, Sahibzada Ajit Singh Nagar, Punjab 160055</a></p>
                                </div>
                                <div className='for-icon-top-spacing'>
                                    <div className='social-icons'>
                                        <a href='https://www.facebook.com/jntwebsolutions/' target='_blank'><FaFacebookF /></a>
                                        <a href='https://www.instagram.com/jntwebsolutions/?igshid=YmMyMTA2M2Y%3D' target='_blank'><FaInstagram /></a>
                                        <a href='https://x.com/jntwebsolutions?lang=en' target='_blank'><FaXTwitter /></a>
                                        <a href='https://www.linkedin.com/company/j-t-web-solutions-pvt-ltd' target='_blank'><FaLinkedinIn /></a>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-lg-8 col-md-12 col-sm-12 contact-form'>
                            <form>
                                <div className='line-flex'>
                                    <div className='input-flex'>
                                        <label>full name</label>
                                        <input type='text'></input>
                                    </div>
                                    <div className='input-flex'>
                                        <label>email address</label>
                                        <input type='text'></input>
                                    </div>
                                </div>
                                <div className='line-flex top-line-flex'>
                                    <div className='input-flex'>
                                        <label>phone number</label>
                                        <input type='text'></input>
                                    </div>
                                    <div className='input-flex'>
                                        <label>enter url</label>
                                        <input type='text'></input>
                                    </div>
                                </div>
                                <div className='top-line-flex'>
                                    <div className='input-flex'>
                                        <label>what of the website do you need?</label>
                                        <div className='radio-input'>
                                            <span>    <input type="radio" id="webdesign" name="fav_language" value="webdesign" />
                                                <label for="webdesign">web design</label><br></br></span>
                                            <span><input type="radio" id="webdevelopment" name="fav_language" value="webdevelopment" />
                                                <label for="webdevelopment">web development</label><br></br></span>
                                            <span>  <input type="radio" id="digitalmarketing" name="fav_language" value="digitalmarketing" />
                                                <label for="digitalmarketing">digital marketing</label></span>
                                            <span>  <input type="radio" id="graphicdesign" name="fav_language" value="graphicdesign" />
                                                <label for="graphicdesign">graphic design</label></span>
                                            <span>  <input type="radio" id="other" name="fav_language" value="other" />
                                                <label for="other">other</label></span>
                                        </div>
                                    </div>

                                </div>
                                <div className='line-flex top-line-flex'>
                                    <div className='input-flex'>
                                        <label>message</label>
                                        <textarea type='text' placeholder='write your message/describe your project managmnet'></textarea>
                                    </div>
                                </div>
                                <div className='btn'>
                                    <a href='#'><button>let's get your project started <span><HiArrowUpRight /></span></button></a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* GET IN TOUCH END */}
        </>
    )
}

export default Form
