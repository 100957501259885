import React from 'react';
import "./Hiring.scss";
import { Link } from 'react-router-dom';
import { HiArrowUpRight } from "react-icons/hi2";
import Form from "../../CommonComp/Form";
import Suceed from "../../CommonComp/Suceed";
import TopBanner from '../../CommonComp/TopBanner';
import banner from "../../Assests/Img/hirig-banner.webp";
import UP from "../../Assests/Img/upwork.webp";
import singlelogo from "../../Assests/Img/jtlogoGroups.webp";
import star from "../../Assests/Img/star.webp";
import hiring1 from "../../Assests/Img/hiring1.webp";
import hiring2 from "../../Assests/Img/hiring2.webp";
import hiring3 from "../../Assests/Img/hiring3.webp";
import Rajesh from "../../Assests/Img/Rajesh.webp";
import Fiver from "../../Assests//Img/fiver.webp";
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
const Hiring = () => {
    return (
        <>
            <div className='hiring' id='hiring'>
                {/* HIRING START */}
                <TopBanner
                    bannerTitle="HIRING"
                    bannerDesc="We help you create the best and reliable website for your business. Your clients would love it and business would sky-rocket"
                    bannerImg={banner}
                    bannerimageAlt="HIRING"
                    bannerimageTitle="HIRING"
                />
                {/* HIRING END */}
                {/* ------------------- */}
                {/* UPWORK START */}
                <div className='container upwork' id='upwork'>
                    <div className='row upwork-inner'>
                        <div className='col-lg-7 img'>
                            <img src={UP} title='UP' alt='UP'></img>
                            <div className='rating'>
                                <a href='https://www.upwork.com/agencies/jntwebsolutions/' target='_blank'>
                                    <button>J&T Web Solutions Pvt. Ltd.</button></a>
                                <div className='rating-inner'>
                                    <span className='star'>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                    </span>
                                    <p>5 star reviews</p>
                                </div>
                            </div>
                            <div className='icon-box'>
                                <div className='icon-box-inner'>
                                    <img src={hiring1} alt='hiring1' title='hiring1'></img>
                                    <p>100% Job Success</p>
                                </div>
                                <div className='icon-box-inner'>
                                    <img src={hiring2} alt='hiring2' title='hiring2'></img>
                                    <p>5,802, Total hours</p>
                                </div>
                                <div className='icon-box-inner'>
                                    <img src={hiring3} alt='hiring3' title='hiring3'></img>
                                    <p>150, Total jobs</p>
                                </div>
                            </div>
                            <p className='text-description'>Web design & development, Mobile design development, Mobile Apps development, SEO, ORM, SMO, CMS-Wordpress, Magento, Joomla, Shopify, Squarespace, Shopify, Frameworks- YII, Laravel,codeigniter, Node.js, Angular.js, Bootstrap, HTML5, CSS3, Jquery</p>
                            <div className='btn-grp'>
                                <div className='btn'><a href='https://www.upwork.com/agencies/jntwebsolutions/' target='_blank'><button>See Profile <span><HiArrowUpRight /></span></button></a></div>
                                <div className='btn'><Link to='/contact'><button className='green-btn'>Book a Consultation <span><HiArrowUpRight /></span></button></Link></div>
                            </div>
                        </div>
                        <div className='col-lg-4 singlelogo'>
                            <img src={singlelogo} alt='singlelogo' title='singlelogo'></img>
                        </div>
                    </div>
                </div>
                {/* UPWORK END */}
                {/* -------------------- */}
                {/* FIVER START */}
                <div id='fiver' className='top-spacing'>
                    <div className='container upwork' id='upwork'>
                        <div className='row upwork-inner'>
                            <div className='col-lg-4 singlelogo'>
                                <img src={Rajesh} alt='Rajesh' title='Rajesh'></img>
                            </div>
                            <div className='col-lg-7 img'>
                                <img src={Fiver} title='Fiver' alt='Fiver'></img>
                                <div className='rating'>
                                    <a href='https://www.upwork.com/agencies/jntwebsolutions/' target='_blank'>
                                        <button>@rajeshkumar617</button>
                                    </a>
                                    <div className='rating-inner'>
                                        <span className='star'>
                                            <img src={star} alt='star' title='star'></img>
                                            <img src={star} alt='star' title='star'></img>
                                            <img src={star} alt='star' title='star'></img>
                                            <img src={star} alt='star' title='star'></img>
                                            <img src={star} alt='star' title='star'></img>
                                        </span>
                                        <p>5 star reviews</p>
                                    </div>
                                </div>
                                <p className='text-description'>J&T Web Solutions Pvt. Ltd. has evolved into a premier digital marketing agency based in India. With years of expertise in delivering top-notch digital marketing services, we have established ourselves as a trusted name in the industry. Our team comprises highly skilled and experienced digital marketers, SEO specialists, content creators, social media managers, and dedicated sales and marketing professionals.</p>
                                <div className='btn-grp'>
                                    <div className='btn'><a href='https://www.fiverr.com/rajeshkumar617' target='_blank'><button>See Profile <span><HiArrowUpRight /></span></button></a></div>
                                    <div className='btn'><Link to='/contact'><button className='green-btn'>Book a Consultation <span><HiArrowUpRight /></span></button></Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* FIVER END */}
                {/* -------------------- */}
                {/* UPWORK START */}
                <div className='container upwork top-spacing' id='upwork'>
                    <div className='row upwork-inner'>
                        <div className='col-lg-7 img'>
                            <img src={UP} title='UP' alt='UP'></img>
                            <div className='rating'>
                                <a href='#' target='_blank'>
                                    <button>J&T Web Solutions Pvt. Ltd.</button>
                                </a>
                                <div className='rating-inner'>
                                    <span className='star'>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                        <img src={star} alt='star' title='star'></img>
                                    </span>
                                    <p>5 star reviews</p>
                                </div>
                            </div>
                            <div className='icon-box'>
                                <div className='icon-box-inner'>
                                    <img src={hiring1} alt='hiring1' title='hiring1'></img>
                                    <p>Top Rated</p>
                                </div>
                                <div className='icon-box-inner'>
                                    <img src={hiring2} alt='hiring2' title='hiring2'></img>
                                    <p>100% Job Success</p>
                                </div>
                                <div className='icon-box-inner'>
                                    <img src={hiring3} alt='hiring3' title='hiring3'></img>
                                    <p>1,423 Total Hours</p>
                                </div>
                            </div>
                            <p className='text-description'>With over II years of professional experience as a WordPress Web developer and expertise in related sectors, what sets me apart from thousands of specialists is my unique approach to problem-solving. I excel in employing innovative methods such as brainstorming and mind mapping to tackle challenges effectively and find creative solutions.</p>
                            <div className='btn-grp'>
                                <div className='btn'><a href='#'><button>See Profile <span><HiArrowUpRight /></span></button></a></div>
                                <div className='btn'><a href='#'><button className='green-btn'>Book a Consultation <span><HiArrowUpRight /></span></button></a></div>
                            </div>
                        </div>
                        <div className='col-lg-4 singlelogo'>
                            <img src={singlelogo} alt='singlelogo' title='singlelogo'></img>
                        </div>
                    </div>
                </div>
                {/* UPWORK END */}
                {/* -------------------- */}
                {/* DIGITAL DREAMS START */}
                <section className='container top-spacing' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>we turn into digital dreams into realty</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Get a FREE Instant Consultation. Let's Start With Your Business Idea Today!</p>
                            <div className='btn'>
                                <Link to='/contact'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* DIGITAL DREAMS END */}
                {/* ------------------- */}
                <Form />
                {/* -------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default Hiring
