import React, { useState, useEffect, useRef } from 'react';
import Logo from "../../Assests/Img/Final-Logo.webp";
import { Link } from 'react-router-dom';
import { HiArrowUpRight } from "react-icons/hi2";
import './Navbar.scss';

const Sidenav = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const sidebarRef = useRef(null);

    const openNav = () => {
        setIsNavOpen(true);
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                closeNav();
            }
        };

        if (isNavOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.classList.add('nav-open');
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.classList.remove('nav-open');
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isNavOpen]);

    return (
        <>
            <div id='navbar'>
                <div className='container'>
                    <div className='navbar'>
                        <div className='logo'>
                            <Link to="/"><img src={Logo} alt='Logo' title='Logo'></img></Link>
                        </div>
                        <div className='navLinks'>
                            <ul>
                                <li> <Link to="/">home</Link></li>
                                <li><Link to='/portfolio'>portfolio</Link></li>
                                <li><Link to='/webdevelopment'>web development</Link></li>
                                <li><Link to="/digitalmarketing">digital marketing</Link></li>
                                <li><Link to='/appdevelopment'>app development</Link></li>
                            </ul>
                        </div>
                        <div className='navbar-btn'>
                            <div className='btn'>
                                <Link to="/contact"><button>request a quote <span><HiArrowUpRight /></span></button>
                                </Link>
                            </div>
                            <span className='hamburger' style={{ fontSize: '30px', cursor: 'pointer' }} onClick={openNav}>&#9776;</span>
                            <div id="mySidenav" className={`sidenav ${isNavOpen ? 'open' : ''}`} ref={sidebarRef}>
                                <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>

                                <Link to="/about" onClick={closeNav}>About</Link>
                                <Link to="/hiring" onClick={closeNav}>Hiring</Link>
                                <Link to="/contact" onClick={closeNav}>Contact</Link>
                            </div>
                            {isNavOpen && <div className="overlay" onClick={closeNav}></div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidenav;
