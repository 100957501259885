import React from 'react';
import "./Common.scss";
import { FaQuoteLeft } from "react-icons/fa";
import { IoStarSharp } from "react-icons/io5";
import { HiArrowLongRight } from "react-icons/hi2";
import { HiArrowLongLeft } from "react-icons/hi2";
import HaldiBrar from "../Assests/Img/HeidiBlair.webp";
import PeterRozsy from "../Assests/Img/Peter-Rozsy.webp";
import Mohamadad from "../Assests/Img/Mohammed-Bin-Tarjim.webp";
import AnthonyAmirthanayagam from "../Assests/Img/AnthonyAmirthanayagam.webp";
import Dejuin from "../Assests/Img/DeJuan.webp";

const Testimonial = () => {
    const testimonials = [
        {
            id: 1,
            name: 'Heidi Blair',
            role: 'client',
            rating: 5.0,
            review: 'Very professional work they do and always on time they get the job done exactly what you are requesting. Their ideas and follow up is excellent with excellent communication.',
            image: HaldiBrar,
            alt: 'Heidi Blair',
        },
        {
            id: 2,
            name: 'Peter Rozsy',
            role: 'client',
            rating: 5.0,
            review: 'They were true to their words, it is hard to select a company for a job and it really is a leap of faith but J&T Websolutions Pvt. Ltd. has proven to be very honest. Really worth the money, thanks very much.',
            image: PeterRozsy,
            alt: 'Peter Rozsy',
        },
        {
            id: 3,
            name: 'Mohammed Bin Tarjim',
            role: 'client',
            rating: 5.0,
            review: 'One of the best people I dealt with, quick to get things done, quick to make adjustments, diligent in their work, I strongly encourage to deal with them.',
            image: Mohamadad,
            alt: 'Mohammed Bin Tarjim',
        },
        {
            id: 4,
            name: 'Anthony Amirthanayagam',
            role: 'client',
            rating: 5.0,
            review: 'They did all the work they promised and was giving me updates weekly, great customer service. I know who I will contact in the future if I need any similar services. Thank you.',
            image: AnthonyAmirthanayagam,
            alt: 'Anthony Amirthanayagam',
        },
        {
            id: 5,
            name: 'Dr. Stevie DeJuan Springer',
            role: 'client',
            rating: 5.0,
            review: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            image: Dejuin,
            alt: 'Dr. Stevie DeJuan Springer',
        },
    ];

    return (
        <>
            {/* TESTIMONIAL START */}
            <section className='container top-spacing' id='testimonial'>
                <div className='row'>
                    <div className='col-lg-5 col-md-12 col-sm-12'>
                        <h4 className='short-head'>client testimonials</h4>
                        <h1 className='main-head'>find reviews from our customers</h1>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12'></div>
                    <div className='row testimonial-main'>
                        <div className='testimonial'>
                            <div id="carouselExampleControls" className="carousel slide">
                                <div className="carousel-inner">
                                    {testimonials.map((testimonial, index) => (
                                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={testimonial.id}>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-4 test-img'>
                                                        <div className='testi-icon'>
                                                            <span><FaQuoteLeft /></span>
                                                        </div>
                                                        <img src={testimonial.image} alt={testimonial.alt} title={testimonial.name}></img>
                                                    </div>
                                                    <div className='col-md-8 test-description'>
                                                        <div className='yellow-star-flex'>
                                                            <div className='yellow-star'>
                                                                {[...Array(5)].map((_, i) => (
                                                                    <span key={i}><IoStarSharp /></span>
                                                                ))}
                                                            </div>
                                                            <span><h6>{testimonial.rating.toFixed(1)}</h6></span>
                                                        </div>
                                                        <p className='text-desciption'>{testimonial.review}</p>
                                                        <h3>{testimonial.name}</h3>
                                                        <h6>{testimonial.role}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                                    <div className='prev-icon'><span className="carousel-control-prev-icon" aria-hidden="true"><HiArrowLongLeft /></span></div>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                                    <div className='next-icon'><span className="carousel-control-next-icon" aria-hidden="true"><HiArrowLongRight /></span></div>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* TESTIMONIAL END */}
        </>
    );
}

export default Testimonial;
