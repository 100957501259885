import React, { useState, useEffect } from 'react';
import "./Home.scss";
import { Link } from 'react-router-dom';
import Form from "../../CommonComp/Form";
import Testimonial from '../../CommonComp/Testimonial';
import Suceed from '../../CommonComp/Suceed';
import { HiArrowUpRight } from "react-icons/hi2";
import Trusted from "../../Assests/Img/Trusted.webp";
import BannerHome from "../../Assests/Img/banner-home.webp";
import AboutUs from "../../Assests/Img/About Us.webp";
import Sign from "../../Assests/Img/Sign.webp";
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
import work1 from "../../Assests/Img/work1.webp";
import work2 from "../../Assests/Img/work2.webp";
import work3 from "../../Assests/Img/work3.webp";
import work4 from "../../Assests/Img/work4.webp";
import work5 from "../../Assests/Img/work5.webp";
import work6 from "../../Assests/Img/work6.webp";
import work7 from "../../Assests/Img/work7.webp";
import work8 from "../../Assests/Img/work8.webp";
import work9 from "../../Assests/Img/work9.webp";
import work10 from "../../Assests/Img/work10.webp";
const Home = () => {
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);

    // portfolioItems
    const portfolioItems = [
        {
            title: 'RAO PLASTIC SURGERY',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            imageClass: 'DrRao bg-img',
            link: '/raoplasticsurgery',
        },
        {
            title: 'Jade Malay',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            imageClass: 'DrRao bg-img jademaley',
            link: '/jademalay',
        },
        {
            title: 'Shamis Tate',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            imageClass: 'DrRao bg-img ShamisTate',
            link: '/shamistate',
        },
        {
            title: 'Accent Roofing Service',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
            imageClass: 'DrRao bg-img accent-roofing',
            link: '/accentroofingservice',
        },
    ];

    // industries
    const industries = [
        { imageSrc: work1, alt: 'work1', title: 'work1', label: 'ecommerce' },
        { imageSrc: work2, alt: 'work2', title: 'work2', label: 'fashion' },
        { imageSrc: work3, alt: 'work3', title: 'work3', label: 'health care' },
        { imageSrc: work4, alt: 'work4', title: 'work4', label: 'real estate' },
        { imageSrc: work5, alt: 'work5', title: 'work5', label: 'jewellery' },
        { imageSrc: work6, alt: 'work6', title: 'work6', label: 'blockchain' },
        { imageSrc: work7, alt: 'work7', title: 'work7', label: 'non fungible token' },
        { imageSrc: work8, alt: 'work8', title: 'work8', label: 'restaurant & bar' },
        { imageSrc: work9, alt: 'work9', title: 'work9', label: 'fintech & banking' },
        { imageSrc: work10, alt: 'work10', title: 'work10', label: 'law firm' }
    ];

    // servicesData
    const servicesData = [
        {
            number: '01.',
            title: 'Digital Marketing',
            buttons: [
                'Search Engine Optimization',
                'Search Engine Marketing',
                'Pay Per Click',
                'Social Media Marketing',
                'Email Marketing',
                'Content Marketing',
            ],
            link: '/digitalmarketing',
        },
        {
            number: '02.',
            title: 'Web Development',
            buttons: [
                'ReactJS Development',
                'Angular JS Development',
                'Wordpress Development',
                'Laravel Development',
                'Php Development',
            ],
            link: '/webdevelopment',
        },
        {
            number: '03.',
            title: 'App Development',
            buttons: [
                'Android App Development',
                'iOS App Development',
                'Flutter App Development',
                'React Native App Development',
            ],
            link: '/appdevelopment',
        },
        {
            number: '04.',
            title: 'Graphic Design',
            buttons: [
                'Web Templates',
                'Brochures',
                'Banner Designs',
                'Business Cards',
                'Brand Identity Design',
                'Packaging Design',
            ],
            link: '/webdevelopment',
        },
        {
            number: '05.',
            title: 'ECommerce Solutions',
            buttons: [
                'Adobe Business Catalyst Migration',
                'WooCommerce',
                'Shopify',
                'Joomla & BigCommerce',
                'Prestashop',
            ],
            link: '/webdevelopment',
        },
    ];


    const counter = (minimum, maximum, setCount) => {
        let intervalId;
        for (let i = minimum; i <= maximum; i++) {
            ((index) => {
                intervalId = setTimeout(() => {
                    setCount(index);
                }, (index - minimum) * 5); // Decreased timeout duration to speed up animation
            })(i);
        }
        // Clear the interval after reaching the maximum value
        return intervalId;
    };

    useEffect(() => {
        const counter1Id = counter(0, 859, setCount1);
        const counter2Id = counter(0, 12, setCount2);
        // Clean up the intervals when component unmounts
        return () => {
            clearTimeout(counter1Id);
            clearTimeout(counter2Id);
        };
    }, []);
    return (
        <>
            {/* BANNER-HOME START */}
            <section id='banner-home'>
                <div className='container top-banner-home' >
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-lg-8'><h1>WE COMPLETE </h1><h1 className='heading-space'>YOUR <span className='red-gradient'>CREATIVE</span> IDEAS</h1></div>
                        <div className='col-md-12 col-sm-12 col-lg-4 talk-now-btn'>
                            <Link to="/contact">
                                <span className='talk-now'>
                                    <span className='talk-now-btn-icon'><HiArrowUpRight /></span>
                                    <h2>LET'S TALK NOW</h2>
                                </span>
                            </Link>
                        </div>
                    </div>
                </div >
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-12 col-sm-12'>
                            <div className='top-banner-left'>
                                <p>An independent web design and branding agency in India set up in 2012 who care, build relationships, have industry experience, and win awards.</p>
                                <div className='Trusted'>
                                    <span><img src={Trusted} alt='Trusted' title='Trusted'></img></span>
                                    <p>Trusted by 150K+ people around the globe</p>

                                </div>
                                <div className='row top-spacing'>
                                    <div className='col-md-6'>
                                        <div className='counting-box blue-box'>
                                            <h3>{count1}+</h3>
                                            <h3>PROJECTS COMPLETED</h3>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='counting-box red-box'>
                                            <h3>{count2}+</h3>
                                            <h3>YEARS OF EXPERIENCE</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-12 col-sm-12 BannerHomeImg'><img src={BannerHome} alt='BannerHome' title='BannerHome'></img></div>
                    </div>
                </div>
            </section>
            {/* BANNER-HOME END */}
            {/* ---------------------- */}
            {/* OUR SERVICES START */}
            <section className='container top-spacing' id='services'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 col-sm-12'>
                        <h4 className='short-head'>OUR SERVICES</h4>
                        <h1 className='main-head'>Discover Our Digital Solutions</h1>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 services-txt'>
                        <p className='text-desc'>At J&T Web Solutions, we specialize in crafting customized web design solutions.</p>
                    </div>
                </div>

                <div className='all-services-box'>
                    {servicesData.map((service, index) => (
                        <div className='row services-box' key={index}>
                            <div className='col-lg-2 col-md-12 col-sm-12'>
                                <h3>{service.number}</h3>
                            </div>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h3>{service.title}</h3>
                                <div className='btn-grp'>
                                    {service.buttons.map((buttonText, btnIndex) => (
                                        <button key={btnIndex}>{buttonText}</button>
                                    ))}
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12'>
                                <div className='btn'>
                                    <Link to={service.link}>
                                        <button>
                                            Learn more <span><HiArrowUpRight /></span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            {/* OUR SERVICES END */}
            {/* ---------------------------- */}
            {/* ABOUT START */}
            <section className='about bg-img' id='about'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 img'><img src={AboutUs} alt='AboutUs' title='AboutUs'></img></div>
                        <div className='col-lg-6 col-md-12 col-sm-12 about-text-desc'>
                            <h4 className='short-head'>about us</h4>
                            <h1 className='main-head'>We're Awards Winning <span className='red-gradient'>Digital Agency</span></h1>
                            <p className='text-desciption'>J&T Web Solutions Pvt. Ltd. is a full-stack digital marketing agency in India. We've provided a wide range of services to clients of all industries since 2012. Services offered by J&T Web Solutions include Website Designing & Website development, and Internet Marketing, Content Development, and Content Management System, Graphic Designing, Branding Logo's, Software Development & Search Engine Optimization (SEO), Pay Per Click (PPC) Online Reputation Management (ORM)</p>
                            <div className='sign'>
                                <div className='btn'>
                                    <Link to='/about'><button>Learn more <span><HiArrowUpRight /></span></button></Link>
                                </div>

                                <div className='sign-img'><img src={Sign} title='Sign' alt='Sign'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ABOUT END */}
            {/* --------------------------------- */}
            {/* PORTFOLIO START */}
            <section className='portfolio top-spacing' id='portfolio'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <h4 className='short-head'>OUR portfolio</h4>
                            <h1 className='main-head'>selected work</h1>
                        </div>
                        <div className='col-md-6'></div>
                    </div>

                    {portfolioItems.map((item, index) => (
                        <div
                            key={index}
                            className={`row portfolio-black-box-top-spacing ${index % 2 === 0 ? 'row-reverse' : 'portfolio-extra-spacing'}`}
                        >
                            {index % 2 === 0 ? (
                                <>
                                    <div className='col-lg-8 col-sm-12 col-md-12'>
                                        <div className='portfolio-black-box'>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-6 left-text'>
                                                        <h3 className='small-head'>design</h3>
                                                        <div className='left-line'>
                                                            <p className='text-desciption'>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                            </p>
                                                            <div className='developing'>
                                                                <h3 className='small-head'>developing</h3>
                                                                <p className='text-desciption'>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                                    </div>
                                                    <div className={`col-md-6 ${item.imageClass}`}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                                        <h3 className='small-head'>{item.title}</h3>
                                        <p className='text-desciption'>{item.description}</p>
                                        <div className='btn'>
                                            <Link to={item.link}>
                                                <button>
                                                    view projects <span><HiArrowUpRight /></span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                                        <h3 className='small-head'>{item.title}</h3>
                                        <p className='text-desciption'>{item.description}</p>
                                        <div className='btn'>
                                            <Link to={item.link}>
                                                <button>
                                                    view projects <span><HiArrowUpRight /></span>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-sm-12 col-md-12'>
                                        <div className='portfolio-black-box'>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-6 left-text'>
                                                        <h3 className='small-head'>design</h3>
                                                        <div className='left-line'>
                                                            <p className='text-desciption'>
                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                            </p>
                                                            <div className='developing'>
                                                                <h3 className='small-head'>developing</h3>
                                                                <p className='text-desciption'>
                                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                                    </div>
                                                    <div className={`col-md-6 ${item.imageClass}`}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            {/* PORTFOLIO END */}
            {/* ---------------------------------------- */}
            {/* STEPS START */}
            <section className='steps bg-img' id='steps'>
                <div className='container'>
                    <div className='row'>
                        <h1 className='main-head'>6 Easy Steps to <br></br> Get Started with Us</h1>
                    </div>
                    <div className='row display-flex'>
                        <div className='col-lg-4 col-md-12 col-sm-12 left-box'>
                            <div className='steps-box '>
                                <div className='count'>
                                    <span>1</span>
                                </div>
                                <h4 className='icon-box-head'>Discussion</h4>
                                <p className='text-desciption'>Get in touch with us to discuss your project requirements and objectives. We'll listen closely to understand your vision and goals</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 middle-box'>
                            <div className='steps-box '>
                                <div className='count'>
                                    <span>2</span>
                                </div>
                                <h4 className='icon-box-head'>Proposal</h4>
                                <p className='text-desciption'>Based on our consultation, we'll provide a detailed proposal outlining the scope of work, timeline, and budget.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 right-box'>
                            <div className='steps-box'>
                                <div className='count'>
                                    <span>3</span>
                                </div>
                                <h4 className='icon-box-head'>Project Kickoff</h4>
                                <p className='text-desciption'>With the agreement in place, we'll schedule a kickoff meeting to introduce you to our team and discuss the project plan in detail.</p>
                            </div>
                        </div>
                    </div>
                    <div className='row display-flex mobile-dispaly-flex'>
                        <div className='col-lg-4 col-md-12 col-sm-12 left-box'>
                            <div className='steps-box '>
                                <div className='count'>
                                    <span>4</span>
                                </div>
                                <h4 className='icon-box-head'>Implementation</h4>
                                <p className='text-desciption'>Our team will start working on your project, keeping you updated with regular progress reports.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 middle-box'>
                            <div className='steps-box '>
                                <div className='count'>
                                    <span>5</span>
                                </div>
                                <h4 className='icon-box-head'>Delivery & Support</h4>
                                <p className='text-desciption'>We value your feedback and will incorporate it into our work. Upon completion, we'll deliver the final product to you.</p>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12 right-box'>
                            <div className='steps-box'>
                                <div className='count'>
                                    <span>6</span>
                                </div>
                                <h4 className='icon-box-head'>Revisions</h4>
                                <p className='text-desciption'>We also provide post-launch support to ensure everything runs smoothly. No limit on the number of revisions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* STEPS END */}
            {/* --------------------------------------- */}
            {/* TESTIMONIAL START */}
            <Testimonial />
            {/* TESTIMONIAL END */}
            {/* ------------------------------------ */}
            {/* DIGITAL DREAMS START */}
            <section className='container top-spacing' id='digital-dreams'>
                <div className='row digital-dreams-inner'>
                    <div className='col-md-5'>
                        <h1 className='main-head'>we turn into digital dreams into realty</h1>
                    </div>
                    <div className='col-md-2 img'>
                        <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                    </div>
                    <div className='col-md-5'>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        <div className='btn'>
                            <Link to='/contact'>
                                <button>learn more <span><HiArrowUpRight /></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* DIGITAL DREAMS END */}
            {/* ---------------------------------------- */}
            {/* INDUSTRIES WORK START */}
            <section id='industries' className='bg-img'>
                <div className='container' >
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12'>
                            <h1 className='main-head'>industries we work for</h1>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 services-txt'>
                            <p className='text-desc'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                    <div className='all-industries-box desktop-show'>
                        {industries.slice(0, 5).map((industry, index) => (
                            <div key={index} className='industries-box'>
                                <div className='box'>
                                    <img src={industry.imageSrc} alt={industry.alt} title={industry.title}></img>
                                </div>
                                <p>{industry.label}</p>
                            </div>
                        ))}
                    </div>
                    <div className='all-industries-box desktop-show'>
                        {industries.slice(5).map((industry, index) => (
                            <div key={index} className='industries-box'>
                                <div className='box'>
                                    <img src={industry.imageSrc} alt={industry.alt} title={industry.title}></img>
                                </div>
                                <p>{industry.label}</p>
                            </div>
                        ))}
                    </div>
                    <div className='all-industries-box mobile-show'>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work1} alt='work1' title='work1'></img>
                                </div>
                                <p>ecommerce</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work2} alt='work2' title='work2'></img>
                                </div>
                                <p>fashion</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work3} alt='work3' title='work3'></img>
                                </div>
                                <p>health care</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work4} alt='work4' title='work4'></img>
                                </div>
                                <p>real estate</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work5} alt='work5' title='work5'></img>
                                </div>
                                <p>jewellery</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work6} alt='work6' title='work6'></img>
                                </div>
                                <p>blockchain</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work7} alt='work7' title='work7'></img>
                                </div>
                                <p>non fungible token</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work8} alt='work8' title='work8'></img>
                                </div>
                                <p>restaurent & bar</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work9} alt='work9' title='work9'></img>
                                </div>
                                <p>fintech & banking</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work9} alt='work9' title='work9'></img>
                                </div>
                                <p>fintech & banking</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work9} alt='work9' title='work9'></img>
                                </div>
                                <p>fintech & banking</p>
                            </div>
                            <div className='industries-box'>
                                <div className='box'>
                                    <img src={work10} alt='work10' title='work10'></img>
                                </div>
                                <p>law firm</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* INDUSTRIES WORK END */}
            {/* -------------------------------------------- */}
            {/* GET IN TOUCH START */}
            <Form />
            {/* GET IN TOUCH END */}
            {/* -------------------------------------------- */}
            {/* SUCEED START */}
            <Suceed />
            {/* SUCEED END */}
        </>
    )
}

export default Home
