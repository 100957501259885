import React from 'react';
import { Link } from 'react-router-dom';
import { HiArrowUpRight } from 'react-icons/hi2';
const Suceed = () => {
    return (
        <div>
            {/* SUCEED START */}
            <section className='container top-spacing suceed' id='digital-dreams'>
                <div className='row digital-dreams-inner'>
                    <div className='col-lg-5 col-md-12 col-sm-12'>
                        <h1 className='main-head'>we just want to see you suceed</h1>
                    </div>
                    <div className='col-lg-2 col-md-12 col-sm-12'>
                    </div>
                    <div className='col-lg-5 col-md-12 col-sm-12'>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        <div className='btn'>
                            <Link to='/contact'>
                                <button>learn more <span><HiArrowUpRight /></span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* SUCEED END */}
        </div>
    )
}

export default Suceed
