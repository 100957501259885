import React from 'react';
import "./Contact.scss";
import { PiClockCountdownLight } from "react-icons/pi";
import { RiTeamLine } from "react-icons/ri";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import Form from "../../CommonComp/Form";
import Suceed from "../../CommonComp/Suceed";
import TopBanner from '../../CommonComp/TopBanner';
import ConactBanner from "../../Assests/Img/ContactBanner.webp";
const Contact = () => {
    return (
        <>
            <div className='contactPage' id='contactPage'>
                {/* ABOUT-BANNER START */}
                <TopBanner
                    bannerTitle="CONTACT US"
                    bannerDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
                    bannerImg={ConactBanner}
                    bannerimageAlt="ConactBanner"
                    bannerimageTitle="ConactBanner"
                />
                {/* GUIDING START */}
                {/* ------------------- */}
                <div className='about-guide container top-spacing' id='about-guide'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12'>
                            <h4 className='short-head'>GUIDING THE FUTURE</h4>
                            <h1 className='main-head'>Nothing less than excellent</h1>
                        </div>
                    </div>
                    <div className='row about-guide-top-spacing'>
                        <div className='col-md-12 col-lg-4'>
                            <div className='icon-box icon-box1'>
                                <div><PiClockCountdownLight /></div>
                                <h3 className='icon-box-head'>Your opinion counts</h3>
                                <p className='text-desciption'>Your opinion matters" or "Your feedback is important</p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-4'>
                            <div className='icon-box icon-box2'>
                                <div><RiTeamLine /></div>
                                <h3 className='icon-box-head'>Teamwork</h3>
                                <p className='text-desciption'>Teamwork is working together to achieve a common goal.</p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-4'>
                            <div className='icon-box icon-box3'>
                                <div><VscWorkspaceTrusted /></div>
                                <h3 className='icon-box-head'>Trust & Integrity</h3>
                                <p className='text-desciption'>Trust and integrity form the foundation of strong relationships.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* --------------------- */}
                {/* MAP START */}
                <div className='map' id='map'>
                    <div style={{ width: '100%', marginTop: '50px' }}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3430.225966313259!2d76.70410417438707!3d30.71204728663418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fef93ee475cd5%3A0xbe2209d6812bdafe!2sJ%26T%20Web%20Solutions%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1722845604284!5m2!1sen!2sin"
                            width="100%"
                            height="500"
                            frameBorder="0"
                            style={{ border: '0' }}
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
                {/* ----------------------- */}
                <Form />
                {/* -------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default Contact
