import React from 'react';
import "./AppDevelopment.scss";
import TopBanner from '../../CommonComp/TopBanner';
import Suceed from '../../CommonComp/Suceed';
import Form from "../../CommonComp/Form";
import { HiArrowUpRight } from "react-icons/hi2";
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
import advantages from "../../Assests/Img/advantages.webp";
import Excellence1 from "../../Assests/Img/excellence1.webp";
import Excellence2 from "../../Assests/Img/service1.webp";
import Excellence3 from "../../Assests/Img/excellenece3.webp";
import bannerImage from "../../Assests/Img/digitalmarketing-banner.webp";
import works from "../../Assests/Img/works.webp";
import helpandsupport from "../../Assests/Img/helpabdsupport.webp";
import AppLogo1 from "../../Assests/Img/applogo1.webp";
import AppLogo2 from "../../Assests/Img/applogo2.webp";
import AppLogo3 from "../../Assests/Img/Flutter.webp";
import AppLogo4 from "../../Assests/Img/applogo4.webp";
const AppDevelopment = () => {
    return (
        <>
            <div className='digitalMarketing appDevelopment' id='digitalMarketing'>
                {/* DIGITAL-MARKETING START */}
                <TopBanner
                    bannerTitle="Mobile App Development"
                    bannerDesc="We are proud to be a trusted provider of Mobile App Development services. Our unwavering commitment is to deliver innovative and top-notch solutions that meet the highest standards."
                    bannerImg={bannerImage}
                    bannerimageAlt="Mobile App Development"
                    bannerimageTitle="Mobile App Development"
                />
                {/* DIGITAL-MARKETING END */}
                {/* --------------------- */}
                <div className='digital-marketing-services' id='digital-marketing-services'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>OUR SERVICES</h4>
                                <h1 className='main-head'>Mobile App Services We Offer</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>We are proud to be a trusted provider of Mobile App Development services. </p>
                            </div>
                        </div>
                        <div className='row digital-marketing-services-spacing'>
                            <div className='col-lg-6'>
                                <div className='icon-box'>
                                    <img src={AppLogo1} alt='AppLogo1' title='AppLogo1'></img>
                                    <h3 class="icon-box-head">
                                        Android App Development</h3>
                                    <ul>
                                        <li>- UI/UX for Android</li>
                                        <li>- Customized Android Apps</li>
                                        <li>- Android App Development and Consulting</li>
                                        <li>- Back-end Solutions</li>
                                        <li>- Support and Maintenance</li>
                                        <li>- Android App Testing</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='icon-box'>
                                    <img src={AppLogo2} alt='AppLogo2' title='AppLogo2'></img>
                                    <h3 class="icon-box-head">IOS App development</h3>
                                    <ul>
                                        <li>- Customized iOS App Development</li>
                                        <li>- Native iOS App Development</li>
                                        <li>- iOS UI/UX Design</li>
                                        <li>- iOS App Upgrade</li>
                                        <li>- iOS Software Testing</li>
                                        <li>- iOS App Development Consulting</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='icon-box'>
                                    <img src={AppLogo3} alt='AppLogo3' title='AppLogo3'></img>
                                    <h3 class="icon-box-head">
                                        Flutter App development</h3>
                                    <ul>
                                        <li>- Flutter App Ideation</li>
                                        <li>- Flutter App Development</li>
                                        <li>- Flutter App Migration</li>
                                        <li>- Testing and Maintenance</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='icon-box'>
                                    <img src={AppLogo4} alt='AppLogo4' title='AppLogo4'></img>
                                    <h3 class="icon-box-head">
                                        Cross-Platform Development</h3>
                                    <ul>
                                        <li>- Cross MultiPlatform App Development</li>
                                        <li>- Cross-Platform App UI/UX</li>
                                        <li>- Cross-Platform App Consulting</li>
                                        <li>- Cross-Platform App Migration</li>
                                        <li>- Cross-Platform App Support</li>
                                        <li>- Cross-Platform App Upgrade</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row digital-marketing-services-spacing'> </div> */}
                    </div>
                </div>
                {/* ------------------------ */}
                {/* HOW IT WORKS START */}
                <div className='container top-spacing' id='how-it-works'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 img'>
                            <img src={works} alt='works' title='works'></img>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <h4 className='short-head'>Proven Excellence</h4>
                            <h1 className='main-head'>Why We’re Your Best Choice </h1>
                            <p className='text-desciption'>We’re your best choice because we offer unmatched quality, personalized service, and a commitment to excellence.</p>
                            <div className='row work-list-icons'>
                                <div className='col-md-12 work-list'>
                                    <span><img src={Excellence1} alt='Excellence1' title='Excellence1'></img>
                                        <div>
                                            <h2>Creative Creations</h2>
                                            <p className='text-desciption'>Innovative,Original And Smart Approach Of Web Designing To Result An Creative Creation.</p>
                                        </div>
                                    </span>
                                </div>
                                <div className='col-md-12 work-list'>
                                    <span><img src={Excellence2} alt='Excellence2' title='Excellence2'></img>
                                        <div>
                                            <h2>Positive Outcomes</h2>
                                            <p className='text-desciption'>Client Oriented Website Development Research And Requirement Analysis Ensure Positive Outcome For Your Business.</p>
                                        </div>
                                    </span>
                                </div>
                                <div className='col-md-12 work-list'>
                                    <span><img src={Excellence3} alt='Excellence3' title='Excellence3'></img>
                                        <div>
                                            <h2>Successful Solutions
                                            </h2>
                                            <p className='text-desciption'>We Convert Complicated Business Concepts Into Elegent And Successsful Solutions.</p>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------ */}
                {/* WORK PROCESS START */}
                <div className='workProcess bg-img top-spacing' id='workProcess'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>Choose Us</h4>
                                <h1 className='main-head'> Why Work With Us</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>At J&T Web Solutions, we provide advanced SEO services to help your site rank higher on search engines.</p>
                            </div>
                        </div>
                        <div className='row display-flex'>
                            <div className='col-lg-3 col-md-12 col-sm-12 left-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>1</span>
                                    </div>
                                    <h4 className='icon-box-head'>better oprtunites</h4>
                                    <p className='text-desciption'>Better opportunities mean chances for improvement in career, education, or life, leading to greater success and growth.</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12 middle-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>2</span>
                                    </div>
                                    <h4 className='icon-box-head'>learning & development</h4>
                                    <p className='text-desciption'>Learning and Development (L&D) focuses on enhancing employee skills and performance through training and education, fostering continuous growth and organizational success.</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12 right-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>3</span>
                                    </div>
                                    <h4 className='icon-box-head'>loving & caring</h4>
                                    <p className='text-desciption'>Loving and caring involve showing affection, compassion, and support towards others, creating strong, positive connections and fostering well-being in relationships.</p>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-12 col-sm-12 left-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>4</span>
                                    </div>
                                    <h4 className='icon-box-head'>maximize your potential</h4>
                                    <p className='text-desciption'>Maximizing your potential involves developing your skills, setting clear goals, and staying focused to achieve your best self and reach your highest level of success.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className='row display-flex'> </div> */}
                    </div>
                </div>
                {/* WORK PROCESS END */}
                {/* ------------------------ */}
                {/* ADVANTAGES START */}
                <div className='advantages container top-spacing' id='advantages'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <img src={advantages} alt='advantages' title='advantages'></img>
                        </div>
                        <div className='col-lg-8 col-md-12'>
                            <h4 className='short-head'>KEY ADVANTAGES</h4>
                            <h1 className='main-head'>Why Choose Us? </h1>
                            <p className='text-description'>These are a few advantages that explain how digital marketing services can be highly effective for Your Business when you partner with the top SEO company.</p>
                            <div className='row top-advantage-spacing'>
                                <div className='col-md-6'>
                                    <div className='icon-box'>
                                        <h4 className='number'>01</h4>
                                        <h3>Asia's Biggest In-house Seo Team</h3>
                                        <p className='text-description'>
                                            We are a top SEO company in India, growing from a small team to a full-stack agency. Our mission is to elevate clients' businesses with advanced and proven SEO strategies.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='icon-box'>
                                        <h4 className='number'>02</h4>
                                        <h3>Crystal-Clear Reporting</h3>
                                        <p className='text-description'>
                                            We offer clear and transparent reporting on the progress of your SEO results. You can track all loads and sales with accuracy with our digital marketing platform.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='icon-box third-box'>
                                        <h4 className='number'>03</h4>
                                        <h3>No Lock-in Contracts</h3>
                                        <p className='text-description'>
                                            Innovative, original and smart approach of web designing to result an creative creation.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='icon-box fourth-box'>
                                        <h4 className='number'>04</h4>
                                        <h3>24/7 Support</h3>
                                        <p className='text-description'>
                                            Innovative, original and smart approach of web designing to result an creative creation.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* ADVANTAGES END */}
                {/* --------------------- */}
                {/* DIGITAL DREAMS START */}
                <section className='container top-spacing let-me-things' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <h1 className='main-head'>Let's Discuss Your Project</h1>
                        <p className='text-desciption'>Get free consultation and let us know your project idea to turn it into an amazing digital product.</p>
                        <div className='btn'>
                            <a href='/contact'>
                                <button>contact us <span><HiArrowUpRight /></span>
                                </button>
                            </a>
                        </div>
                    </div>
                </section>
                {/* DIGITAL DREAMS END */}
                {/* ----------------------- */}
                {/* HELP AND SUPPORT START */}
                <div className='advantages container top-spacing help-and-support' id='advantages'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <h4 className='short-head'>HELP & SUPPORT</h4>
                            <h1 className='main-head'>Frequently Asked Questions</h1>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is the typical timeline for building a website?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>The typical timeline for building a website can range from 4 weeks for a simple site to 3 months or more for a complex one. It depends on factors like project scope, design, development, and client feedback.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How responsive are my websites?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast.                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            What is the best way to choose a web development company?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>
                                                <ol>
                                                    <li><b>Research:</b> Research potential companies, check portfolios, and read client reviews.</li>
                                                    <li><b>Experience:</b> Look for companies with experience in your industry and the specific features you need.</li>
                                                    <li><b>Communication:</b> Assess their communication skills and responsiveness.</li>
                                                    <li><b>Budget:</b>Compare quotes, ensuring they align with your budget and project scope.</li>
                                                    <li><b>Technology:</b>Ensure they use up-to-date web development technologies.</li>
                                                    <li><b>References:</b>Ask for references and contact past clients for feedback.</li>
                                                    <li><b>Timeline:</b> Discuss project timelines and deadlines.</li>
                                                    <li><b>Agreement: </b>Review and understand the contract terms and deliverables before committing.</li>
                                                </ol>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            How much does website development cost?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <img src={helpandsupport} alt='helpandsupport' title='helpandsupport'></img>
                        </div>
                    </div>
                </div>
                {/* HELP AND SUPPORT END */}
                {/* ----------------------------- */}
                {/* BETTER DISCUSIION START */}
                <section className='container top-spacing discussion' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>Better Discussions, Greater Conversions</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Get a FREE Instant Consultation. Let's Start With Your Business Idea Today!</p>
                            <div className='btn'>
                                <a href='#'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* BETTER DISCUSIION END */}
                {/* ---------------------- */}
                {/* CONTACT US START */}
                <Form />
                {/* CONTACT US END */}
                {/* -------------------------------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>

        </>
    )
}

export default AppDevelopment

