import React from 'react'
import { HiArrowUpRight } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import Trusted from "../../../Assests/Img/Trusted.webp";
import "./PortfolioInnerPage.scss"
const InnerTabWordpress = () => {
    return (
        <>
            <section className='portfolio top-spacing' id='portfolio'>
                <div className='row portfolio-black-box-top-spacing row-reverse'>
                    <div className='col-lg-8 col-sm-12 col-md-12'>
                        <div className='portfolio-black-box'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 left-text'>
                                        <h3 className='small-head'>design</h3>
                                        <div className='left-line'>
                                            <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <div className='developing'>
                                                <h3 className='small-head'>developing</h3>
                                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                    </div>
                                    <div className='col-md-6 DrRao bg-img'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                        <h3 className='small-head'>RAO PLASTIC SURGERY</h3>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                        <div className='btn'>
                            <Link to="/raoplaticsurgery"><button>view projects <span><HiArrowUpRight /></span></button></Link>
                        </div>
                    </div>
                </div>
                <div className='row portfolio-black-box-top-spacing portfolio-extra-spacing'>
                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                        <h3 className='small-head'>Jade Malay
                        </h3>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                        <div className='btn'>
                            <Link to="/jademalay"><button>view projects <span><HiArrowUpRight /></span></button></Link>
                        </div>
                    </div>
                    <div className='col-lg-8 col-sm-12 col-md-12'>
                        <div className='portfolio-black-box'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 left-text'>
                                        <h3 className='small-head'>design</h3>
                                        <div className='left-line'>
                                            <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <div className='developing'>
                                                <h3 className='small-head'>developing</h3>
                                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                    </div>
                                    <div className='col-md-6 DrRao bg-img jademaley'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='row portfolio-black-box-top-spacing row-reverse'>
                    <div className='col-lg-8 col-sm-12 col-md-12'>
                        <div className='portfolio-black-box'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 left-text'>
                                        <h3 className='small-head'>design</h3>
                                        <div className='left-line'>
                                            <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <div className='developing'>
                                                <h3 className='small-head'>developing</h3>
                                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                    </div>
                                    <div className='col-md-6 DrRao bg-img ShamisTate'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                        <h3 className='small-head'>Shamis Tate</h3>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                        <div className='btn'>
                            <Link to="/shamistate"><button>view projects <span><HiArrowUpRight /></span></button></Link>
                        </div>
                    </div>
                </div>
                <div className='row portfolio-black-box-top-spacing portfolio-extra-spacing'>
                    <div className='col-lg-4 col-sm-12 col-md-12 portfolio-right'>
                        <h3 className='small-head'>Accent Roofing Service</h3>
                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p>
                        <div className='btn'>
                            <Link to="/accentroofingservice"><button>view projects <span><HiArrowUpRight /></span></button></Link>
                        </div>
                    </div>
                    <div className='col-lg-8 col-sm-12 col-md-12'>
                        <div className='portfolio-black-box'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6 left-text'>
                                        <h3 className='small-head'>design</h3>
                                        <div className='left-line'>
                                            <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            <div className='developing'>
                                                <h3 className='small-head'>developing</h3>
                                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                            </div>
                                        </div>
                                        <img src={Trusted} alt='Trusted' title='Trusted'></img>
                                    </div>
                                    <div className='col-md-6 DrRao bg-img accent-roofing'>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='btn portfolio-btn'>
                    <a href='#'><button>see more <span><HiArrowUpRight /></span></button></a>
                </div>
            </section>
        </>
    )
}

export default InnerTabWordpress
