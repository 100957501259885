import React from 'react'
import './PortfolioTabs.scss';
import Banner1 from "../../../Assests/Img/Banner1.webp";
import Banner2 from "../../../Assests/Img/Banner2.webp";
import Banner3 from "../../../Assests/Img/Banner3.webp";
import Banner4 from "../../../Assests/Img/Banner4.webp";
import Banner5 from "../../../Assests/Img/Banner5.webp";
const Banner = () => {
    const Banner = [
        { id: 'Banner1', src: Banner1, imgSrc: Banner1, title: "Banner1" },
        { id: 'Banner2', src: Banner2, imgSrc: Banner2, title: "Banner2" },
        { id: 'Banner3', src: Banner3, imgSrc: Banner3, title: "Banner3" },
        { id: 'Banner4', src: Banner4, imgSrc: Banner4, title: "Banner4" },
        { id: 'Banner5', src: Banner5, imgSrc: Banner5, title: "Banner5" }
    ];
    return (
        <div>
            <div className='container logosPage' id='BannerPage'>
                <div className='top-spacing row'>
                    <div className='col-lg-7 col-md-12 col-sm-12'>
                        <h4 className='short-head'>OUR PORTFOLIO</h4>
                        <h1 className='main-head'>Banner design portfolio</h1>
                    </div>
                </div>
                <div className='row'>
                    {
                        Banner.map((logo, id) => (
                            <div className='col-md-4 logo-box-inner'>
                                <div key={id} className='logo-box'>
                                    <img src={logo.src} title={logo.title}></img>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Banner
