import CaseStudyInner from './CaseStudyInner';
import leapwideBanner from "../../../Assests/Img/LeapWideMain.webp";
import leapwide1 from "../../../Assests/Img/leapwide1.webp";
import leapwide2 from "../../../Assests/Img/leapwide2.webp";
import leapwide3 from "../../../Assests/Img/leapwide3.webp";
import leapwide4 from "../../../Assests/Img/leapwide4.webp";
import leapwideMockup from "../../../Assests/Img/leapwideMockup.webp";
const LeapWide = () => {
    return (
        <CaseStudyInner
            bannerSrc={leapwideBanner}
            altText="leapwideBanner"
            titleText="leapwideBanner"
            heading="leapwide"
            description="LeapWide is a software and digital company where perfect software, websites, mobile apps and all things digital come to life."
            buttonText="learn more"
            link="https://leapwide.dgquick.in/"

            // case-information

            technology="react"
            project="revamp website"
            customer="Saurabh matta"
            timeduration="20 Days"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={leapwide1}
            personalize2={leapwide2}
            personalize1alt="leapwide1"
            personalize2alt="leapwide1"
            personalize1title="leapwide2"
            personalize2title="leapwide2"

            // challenges
            challengesimg={leapwide3}
            challengesalt="leapwide3"
            challengestitle="leapwide3"
            challengesdesc="LeapWide is a full service software company for small to medium scale organizations, visionaries, and big-thinkers."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="A Unique Approach to Corporate Wellness"
            enhancingdesc="Derek Opperman is a certified Corporate Wellness coach in LA across multiple health disciplines, from fitness training to nutrition design, and integrative lifestyle planning. He’s also certified as a Corporate Wellness Specialist and is able to speak about the importance of health at a company-wide, cultural level."
            list1="adobe photoshop"
            list2="react"
            list3="google fonts"
            enhancingimg={leapwideMockup}
            enhancingtitle="leapwideMockup"
            enhancingalt="leapwideMockup"

            // work-we-do
            workimg={leapwide4}
            workalt="leapwide4"
            worktitle="leapwide4"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default LeapWide;







