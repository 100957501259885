import React from 'react';
import "./DigitalMarketing.scss";
import { HiArrowUpRight } from "react-icons/hi2";
import Form from "../../CommonComp/Form";
import Suceed from '../../CommonComp/Suceed';
import TopBanner from '../../CommonComp/TopBanner';
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
import advantages from "../../Assests/Img/advantages.webp";
import banner from "../../Assests/Img/digitalmarketing-banner.webp";
import works from "../../Assests/Img/works.webp";
import helpandsupport from "../../Assests/Img/helpabdsupport.webp";
import service1 from "../../Assests/Img/service1.webp";
import service2 from "../../Assests/Img/service2.webp";
import service3 from "../../Assests/Img/service3.webp";
import service4 from "../../Assests/Img/service4.webp";
import service5 from "../../Assests/Img/service5.webp";
import service6 from "../../Assests/Img/service6.webp";
import number1 from "../../Assests/Img/number1.webp";
import number2 from "../../Assests/Img/number2.webp";
import number3 from "../../Assests/Img/number3.webp";
import number4 from "../../Assests/Img/number4.webp";
const DigitalMarketing = () => {
  
    return (
        <>
            <div className='digitalMarketing' id='digitalMarketing'>
                {/* DIGITAL-MARKETING START */}
                <TopBanner
                    bannerTitle="DIGITAL MARKETING"
                    bannerDesc="At J&T Web Solutions, we understand the dynamic nature of the digital world, and we are here to help you navigate it with precision and expertise."
                    bannerImg={banner}
                    bannerimageAlt="digiatalbanner"
                    bannerimageTitle="digiatalbanner"
                />
                {/* DIGITAL-MARKETING END */}
                {/* --------------------- */}
                <div className='digital-marketing-services' id='digital-marketing-services'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>OUR SERVICES</h4>
                                <h1 className='main-head'>Digital Marketing Services We Offer</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>We are your gateway to unparalleled digital marketing services that drive results and elevate.</p>
                            </div>
                        </div>
                        <div className='row digital-marketing-services-spacing'>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service1} alt='service1' title='service1'></img>
                                    <h3 class="icon-box-head">Search Engine Optimization</h3>
                                    <ul>
                                        <li>- On-Page SEO</li>
                                        <li>- Off-Page SEO</li>
                                        <li>- Technical SEO</li>
                                        <li>- Local SEO</li>
                                        <li>- ECommerce SEO</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service2} alt='service2' title='service2'></img>
                                    <h3 class="icon-box-head">Online Reputation Management</h3>
                                    <ul>
                                        <li>- Brand Reputation Management</li>
                                        <li>- Corporate Reputation Management</li>
                                        <li>- Celebrity Reputation Management</li>
                                        <li>- Personal Reputation Management</li>
                                        <li>- Crisis Management</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service3} alt='service3' title='service3'></img>
                                    <h3 class="icon-box-head">Pay Per Click (PPC)</h3>
                                    <ul>
                                        <li>- Search Ads</li>
                                        <li>- Display Ads</li>
                                        <li>- Remarketing Ads</li>
                                        <li>- Google Shopping Ads</li>
                                        <li>- In-Stream Ads</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row digital-marketing-services-spacing'>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service4} alt='service4' title='service4'></img>
                                    <h3 class="icon-box-head">Social Media Marketing</h3>
                                    <ul>
                                        <li>- Facebook Marketing</li>
                                        <li>- Instagram Marketing</li>
                                        <li>- Linkedin Marketing</li>
                                        <li>- Pinterest Marketing</li>
                                        <li>- Twitter Marketing</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service5} alt='service5' title='service5'></img>
                                    <h3 class="icon-box-head">Search Engine Marketing</h3>
                                    <ul>
                                        <li>- Keyword Research And Strategy</li>
                                        <li>- Amazon PPC</li>
                                        <li>- Landing Page Conversion</li>
                                        <li>- Paid Search Advertising</li>
                                        <li>- Remarketing</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='icon-box'>
                                    <img src={service6} alt='service6' title='service6'></img>
                                    <h3 class="icon-box-head">E-Commerce Marketing</h3>
                                    <ul>
                                        <li>- E-Commerce SEO</li>
                                        <li>- E-Commerce Content Marketing</li>
                                        <li>- E-Commerce PPC Management</li>
                                        <li>- Shopify SEO</li>
                                        <li>- Amazon Marketing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------ */}
                {/* HOW IT WORKS START */}
                <div className='container top-spacing' id='how-it-works'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-12 img'>
                            <img src={works} alt='works' title='works'></img>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            <h4 className='short-head'>HOW IT WORKS</h4>
                            <h1 className='main-head'>Dedicated Digital Marketing Project Manager</h1>
                            <p className='text-desciption'>Our dedicated SEO project managers are the reasons we call ourselves the best SEO agency in India. They stay updated with the latest SEO tools and serve day and night shifts to ensure 24/7 support to our customers. When you join hands with us, we will provide you with a dedicated SEO project manager who will look after all the necessary aspects of your project. These SEO consultants will be responsible for planning, organizing, and directing the completion of tasks for your business website while ensuring the projects are on time, on budget, and within scope. Moreover, our project leaders keep the clients updated with a weekly progress report to let them keep track of the results.</p>
                            <div className='row work-list-icons'>
                                <div className='col-md-6 work-list'>
                                    <span>
                                        <img src={number1} alt='number1' title='number1'></img>
                                        <p>Easy Communication</p>
                                    </span>
                                </div>
                                <div className='col-md-6 work-list'>
                                    <span>
                                        <img src={number2} alt='number2' title='number2'></img>
                                        <p>Goal-Oriented Plans</p>
                                    </span>
                                </div>
                                <div className='col-md-6 work-list'>
                                    <span>
                                        <img src={number3} alt='number3' title='number3'></img>
                                        <p>Devoted Team Members</p>
                                    </span>
                                </div>
                                <div className='col-md-6 work-list'>
                                    <span>
                                        <img src={number4} alt='number4' title='number4'></img>
                                        <p>Crystal Clear Details</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ------------------------ */}
                {/* WORK PROCESS START */}
                <div className='workProcess bg-img top-spacing' id='workProcess'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>OUR SERVICES</h4>
                                <h1 className='main-head'>our work process</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>We believe in a strong work ethics, It works as a cornerstone for our company's culture.</p>
                            </div>
                        </div>
                        <div className='row display-flex'>
                            <div className='col-lg-4 col-md-12 col-sm-12 left-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>1</span>
                                    </div>
                                    <h4 className='icon-box-head'>Discover Stage</h4>
                                    <p className='text-desciption'>We at J&T have a dedicated link-building team and have connections with several high-quality and relevant websites,</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 middle-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>2</span>
                                    </div>
                                    <h4 className='icon-box-head'>Analysing</h4>
                                    <p className='text-desciption'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 right-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>3</span>
                                    </div>
                                    <h4 className='icon-box-head'>Building Strategies</h4>
                                    <p className='text-desciption'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when</p>
                                </div>
                            </div>
                        </div>
                        <div className='row display-flex'>
                            <div className='col-lg-4 col-md-12 col-sm-12 left-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>4</span>
                                    </div>
                                    <h4 className='icon-box-head'>Execution</h4>
                                    <p className='text-desciption'>Get in touch with us to discuss your project requirements and objectives. We'll listen closely to understand your vision and goals</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 middle-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>5</span>
                                    </div>
                                    <h4 className='icon-box-head'>Reports</h4>
                                    <p className='text-desciption'>Based on our consultation, we'll provide a detailed proposal outlining the scope of work, timeline, and budget.</p>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12 right-box'>
                                <div className='steps-box'>
                                    <div className='count'>
                                        <span>6</span>
                                    </div>
                                    <h4 className='icon-box-head'>24*7 Customer Service</h4>
                                    <p className='text-desciption'>With the agreement in place, we'll schedule a kickoff meeting to introduce you to our team and discuss the project plan in detail.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* WORK PROCESS END */}
                {/* ------------------------ */}
                {/* ADVANTAGES START */}
                <div className='advantages container top-spacing' id='advantages'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-12'>
                            <img src={advantages} alt='advantages' title='advantages'></img>
                        </div>
                        <div className='col-lg-8 col-md-12'>
                            <h4 className='short-head'>KEY ADVANTAGES</h4>
                            <h1 className='main-head'>Benefits Of Digital Marketing Services To Your Business</h1>
                            <p className='text-description'>These are a few advantages that explain how digital marketing services can be highly effective for Your Business when you partner with the top SEO company.</p>
                            <div className='row top-advantage-spacing'>
                                <div className='col-md-6'>
                                    <div className='icon-box'>
                                        <h4 className='number'>01</h4>
                                        <h3>Global Reach</h3>
                                        <p className='text-description'>
                                            With digital marketing, you can reach a global audience, breaking through geographical barriers and expanding your market.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='icon-box'>
                                        <h4 className='number'>02</h4>
                                        <h3>Cost-Effective</h3>
                                        <p className='text-description'>
                                            Digital marketing is often more cost-effective than traditional marketing channels like TV or print ads.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='icon-box'>
                                        <h4 className='number'>03</h4>
                                        <h3>Higher Engagement</h3>
                                        <p className='text-description'>
                                            Digital marketing channels such as social media and email marketing enable direct engagement with your audience.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-md-6'> <div className='icon-box'>
                                    <h4 className='number'>04</h4>
                                    <h3>24/7 Marketing</h3>
                                    <p className='text-description'>
                                        Unlike traditional marketing that operates within set hours, digital marketing operates 24/7, allowing you to reach your audience anytime.
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ADVANTAGES END */}
                {/* --------------------- */}
                {/* DIGITAL DREAMS START */}
                <section className='container top-spacing let-me-things' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <h1 className='main-head'>Let's Make Things Happen</h1>
                        <p className='text-desciption'>Partner with the #1 ranked digital marketing agency-before your competitor does.</p>
                        <div className='btn'>
                            <a href='#'>
                                <button>learn more <span><HiArrowUpRight /></span>
                                </button>
                            </a>
                        </div>
                    </div>
                </section>
                {/* DIGITAL DREAMS END */}
                {/* ----------------------- */}
                {/* HELP AND SUPPORT START */}
                <div className='advantages container top-spacing help-and-support' id='advantages'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <h4 className='short-head'>HELP & SUPPORT</h4>
                            <h1 className='main-head'>Frequently Asked Questions</h1>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Can I get Quick SEO Results?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How can social media marketing help my business?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Why is digital marketing important?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            What are the benefits of SEO for digital marketing?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Is digital marketing suitable for all businesses?
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <img src={helpandsupport} alt='helpandsupport' title='helpandsupport'></img>
                        </div>
                    </div>
                </div>
                {/* HELP AND SUPPORT END */}
                {/* ----------------------------- */}
                {/* BETTER DISCUSIION START */}
                <section className='container top-spacing discussion' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>Better Discussions, Greater Conversions</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Get a FREE Instant Consultation. Let's Start With Your Business Idea Today!</p>
                            <div className='btn'>
                                <a href='#'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* BETTER DISCUSIION END */}
                {/* ---------------------- */}
                {/* CONTACT US START */}
                <Form />
                {/* CONTACT US END */}
                {/* -------------------------------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default DigitalMarketing
