import React from 'react';
import "./CaseStudyInner.scss";
import { HiArrowUpRight } from "react-icons/hi2";
import { TbBulb } from "react-icons/tb";
import { PiProjectorScreenChartLight } from "react-icons/pi";
import { RiUser3Line } from "react-icons/ri";
import { MdOutlineAvTimer } from "react-icons/md";
import SingleTestimonial from '../../../CommonComp/SingleTestimonial';
import Suceed from '../../../CommonComp/Suceed';
import HaldiBrar from "../../../Assests/Img/HeidiBlair.webp";;

const CaseStudyInner = (
    {
        // casestudyinner
        bannerSrc,
        altText,
        titleText,
        heading,
        description,
        buttonText,
        link,

        // case-information
        technology,
        project,
        customer,
        timeduration,

        // personalize
        personalizedesc1,
        personalizedesc2,
        personalizedesc3,
        personalize1,
        personalize2,
        personalize1alt,
        personalize2alt,
        personalize1title,
        personalize2title,

        // challenges
        challengesimg,
        challengesalt,
        challengestitle,
        challengesdesc,
        accordion1head,
        accordion1desc,
        accordion2head,
        accordion2desc,
        accordion3head,
        accordion3desc,
        enhancinghead,
        enhancingdesc,
        list1,
        list2,
        list3,
        enhancingimg,
        enhancingtitle,
        enhancingalt,

        // work-we-do
        workimg,
        workalt,
        worktitle,
        worklist1,
        worklist2,
        worklist3,
        worklist4,
        worklist5,
        worklist6,

    }
) => {
    return (
        <>
            <div id='CaseStudyInner'>
                {/* casestudyinner start */}
                <div className='container casestudyinner'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 img'>
                            <img src={bannerSrc} alt={altText} title={titleText}></img>
                        </div>
                        <div className='col-lg-6 col-md-6 left-text'>
                            <h1 className='main-head banner-head'>{heading}</h1>
                            <p className='text-desciption'>{description}</p>
                            <div className='btn btn-extra-space'>
                                <a href={link} target='_blank'>
                                    <button>{buttonText} <span><HiArrowUpRight /></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* casestudyinner end */}
                {/* ------------------ */}
                {/* case information start */}
                <div id='case-information'>
                    <div className='container case-information'>
                        <h4 class="short-head">details about the project</h4>
                        <h1 class="main-head">case information</h1>
                        <div className='row case-information-top'>
                            <div className='col-md-3 icon-box'>
                                <span><TbBulb /></span>
                                <span className='icon-box-text'>
                                    <h4>technology</h4>
                                    <p>{technology}</p>
                                </span>
                            </div>
                            <div className='col-md-3 icon-box'>
                                <span><PiProjectorScreenChartLight /></span>
                                <span className='icon-box-text'>
                                    <h4>project</h4>
                                    <p>{project}</p>
                                </span>
                            </div>
                            <div className='col-md-3 icon-box'>
                                <span><RiUser3Line /></span>
                                <span className='icon-box-text'>
                                    <h4>customer</h4>
                                    <p>{customer}</p>
                                </span>
                            </div>
                            <div className='col-md-3 icon-box icon-box-last-child'>
                                <span><MdOutlineAvTimer /></span>
                                <span className='icon-box-text'>
                                    <h4>time duration</h4>
                                    <p>{timeduration}</p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* case information end */}
                {/* ------------------ */}
                {/* personalization start */}
                <div className='personalize container' id='personalize'>
                    <h1 class="main-head">personalization & customization</h1>
                    <p className='text-desciption'>{personalizedesc1}
                        <br></br><br></br>
                        {personalizedesc2}
                    </p>
                    <div className='row img-grp'>
                        <div className='col-md-6'><img src={personalize1} alt={personalize1alt} title={personalize1title}></img></div>
                        <div className='col-md-6'><img src={personalize2} alt={personalize2alt} title={personalize2title}></img></div>
                    </div>
                    <p className='text-desciption'>{personalizedesc3}</p>
                </div>
                {/* personalization end */}
                {/* ------------------------ */}
                {/* the challenges start */}
                <div id='challenges' children="bg-img">
                    <div className='container challenges'>
                        <div className='row'>
                            <div className='col-md-6 img-side'>
                                <h1 class="main-head">The<br></br> challenges</h1>
                                <img src={challengesimg} alt={challengesalt} title={challengestitle}></img>
                            </div>
                            <div className='col-md-6'>
                                <p className='text-desciption'>{challengesdesc}
                                </p>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                {accordion1head}
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p className='text-desciption'>{accordion1desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                {accordion2head}
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <p className='text-desciption'>{accordion2desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item last-child">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                {accordion3head}
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="accordion-body">
                                                <p className='text-desciption'>{accordion3desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* the challenges end */}
                {/* ----------------------- */}
                {/* enhanicng beauty start */}
                <div className='container top-spacing' id='enhancing-beauty'>
                    <div className='row'>
                        <div className='col-md-6 left-text'>
                            <h1 class="main-head">{enhancinghead}</h1>
                            <p className='text-desciption'>
                                {enhancingdesc}
                            </p>
                            <h1 class="main-head head-space">designing</h1>
                            <ul className='list-hover'>
                                <li>{list1}</li>
                                <li>{list2}</li>
                                <li>{list3}</li>
                            </ul>
                        </div>
                        <div className='col-md-6 img'>
                            <img src={enhancingimg} title={enhancingtitle} alt={enhancingalt}></img>
                        </div>
                    </div>
                </div>
                {/* enhanicng beauty end */}
                {/* ---------------------- */}
                {/* work we do start */}
                <div className='work-we-do top-spacing' id='work-we-do'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img src={workimg} alt={workalt} title={worktitle}></img>
                        </div>
                        <div className='col-md-6 black-bg'>
                            <h1 class="main-head">work we do</h1>
                            <ul className='list-hover'>
                                <li>{worklist1}</li>
                                <li>{worklist2}</li>
                                <li>{worklist3}</li>
                                <li>{worklist4}</li>
                                <li>{worklist5}</li>
                                <li>{worklist6}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* work we do end */}
                {/* ------------------------ */}
                {/* TESTIMONIAL START */}
                <SingleTestimonial
                    testiimg={HaldiBrar}
                    testialt="HaldiBrar"
                    testititle="HaldiBrar"
                    name="heidi blair"
                    desc="Very professional work they do and always on time they get the job done exactly what you are requesting. Their ideas and follow up is excellent with excellent communication."
                />
                {/* TESTIMONIAL END */}
                {/* ---------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default CaseStudyInner












