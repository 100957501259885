import CaseStudyInner from './CaseStudyInner';
import Lucknowiandaaz from "../../../Assests/Img/lucknowiandaazMain.webp";
import lucknowiandaaz1 from "../../../Assests/Img/lucknowiandaaz1.webp";
import lucknowiandaaz2 from "../../../Assests/Img/lucknowiandaaz2.webp";
import lucknowiandaaz3 from "../../../Assests/Img/lucknowiandaaz3.webp";
import lucknowiandaazMockup from "../../../Assests/Img/lucknowiandaazMockup.webp";
import lucknowiandaaz4 from "../../../Assests/Img/lucknowiandaaz4.webp";
const lucknowiAndaaz = () => {
    return (
        <CaseStudyInner
            bannerSrc={Lucknowiandaaz}
            altText="Lucknowiandaaz"
            titleText="Lucknowiandaaz"
            heading="lucknowi andaaz"
            description="Lucknowi Andaaz was founded in January 2014 by a mother-daughter duo, Punam and Supreet Bhatia with a dream to promote the marvelous art of chikankari from the city of Nawabs, Lucknow."
            buttonText="learn more"
            link="https://www.lucknowiandaaz.in/"

            // case-information

            technology="shopify"
            project="revamp website"
            customer=""
            timeduration=""

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={lucknowiandaaz1}
            personalize2={lucknowiandaaz2}
            personalize1alt="lucknowiandaaz1"
            personalize2alt="lucknowiandaaz1"
            personalize1title="lucknowiandaaz2"
            personalize2title="lucknowiandaaz2"

            // challenges
            challengesimg={lucknowiandaaz3}
            challengesalt="lucknowiandaaz3"
            challengestitle="lucknowiandaaz3"
            challengesdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="about us"
            enhancingdesc="Lucknowi Andaaz was founded in January 2014 by a mother-daughter duo, Punam and Supreet Bhatia with a dream to promote the marvelous art of chikankari from the city of Nawabs, Lucknow. Their aim was to reach out to the masses with a varied and affordable hand embroidered chikankari collection. With the e-commerce industry being at its peak in India, LucknowiAndaaz.in was launched and within a matter of few months, Lucknowi Andaaz was getting appreciated for its quality products and timely delivery. "
            list1="adobe photoshop"
            list2="shopify"
            list3="google fonts"
            enhancingimg={lucknowiandaazMockup}
            enhancingtitle="lucknowiandaazMockup"
            enhancingalt="lucknowiandaazMockup"

            // work-we-do
            workimg={lucknowiandaaz4}
            workalt="lucknowiandaaz4"
            worktitle="lucknowiandaaz4"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default lucknowiAndaaz;




