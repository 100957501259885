import CaseStudyInner from './CaseStudyInner';
import banner from '../../../Assests/Img/rao-banner.webp';
import rao1 from "../../../Assests/Img/rao1.webp";
import rao2 from "../../../Assests/Img/rao2.webp";
import rao3 from "../../../Assests/Img/rao3.webp";
import raoplasticsurgery from "../../../Assests/Img/raoplasticsurgery.webp";
import DrRao from "../../../Assests/Img/dr-rao.webp";
const RaoPlaticSurgery = () => {
    return (
        <CaseStudyInner
            bannerSrc={banner}
            altText="banner"
            titleText="banner"
            heading="rao plastic surgery"
            description="Our team of Web Developers are highly skilled and capable of developing any kind of website, ensuring that it aligns with our clients' specific goals and requirements."
            buttonText="learn more"
            link="https://raoplasticsurgery.com/"

            // case-information

            technology="wordpress"
            project="re-design website"
            customer="Dr. Arun Rao"
            timeduration="1 month"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={rao1}
            personalize2={rao2}
            personalize1alt="rao1"
            personalize2alt="rao2"
            personalize1title="rao1"
            personalize2title="rao1"

            // challenges
            challengesimg={rao3}
            challengesalt="rao3"
            challengestitle="rao3"
            challengesdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="enhancing beauty restoring confidence"
            enhancingdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            list1="adobe photoshop"
            list2="wordpress"
            list3="google fonts"
            enhancingimg={raoplasticsurgery}
            enhancingtitle="raoplasticsurgery"
            enhancingalt="raoplasticsurgery"

            // work-we-do
            workimg={DrRao}
            workalt="DrRao"
            worktitle="DrRao"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default RaoPlaticSurgery;



