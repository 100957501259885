import React from 'react'
import InnerTabWordpress from "../PortfolioInnerPage/InnerTabWordpress";
import InnerTabReact from "../PortfolioInnerPage/InnerTabReact";
import PortfolioInnerShopify from "../PortfolioInnerPage/PortfolioInnerShopify";
const WesiteTamplate = () => {
    return (
        <>
            {/* ----first-pills start--- */}
            <div className='top-spacing row'>
                <div className='col-lg-7 col-md-12 col-sm-12'>
                    <h4 className='short-head'>OUR PORTFOLIO</h4>
                    <h1 className='main-head'>Web Development Projects</h1>
                </div>
                <div className='col-lg-5 col-md-12 col-sm-12 tablist-bottom'>
                    <ul class="nav nav-tabs" id="ex1" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link active"
                                id="ex3-tab-1"
                                data-bs-toggle="tab"
                                href="#ex3-tabs-1"
                                role="tab"
                                aria-controls="ex3-tabs-1"
                                aria-selected="true"
                            >React</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link"
                                id="ex3-tab-2"
                                data-bs-toggle="tab"
                                href="#ex3-tabs-2"
                                role="tab"
                                aria-controls="ex3-tabs-2"
                                aria-selected="false"
                            >Wordpress</a >
                        </li>
                        <li class="nav-item" role="presentation">
                            <a
                                class="nav-link"
                                id="ex3-tab-3"
                                data-bs-toggle="tab"
                                href="#ex3-tabs-3"
                                role="tab"
                                aria-controls="ex3-tabs-3"
                                aria-selected="false"
                            >Shopify</a >
                        </li>
                    </ul>
                </div>
            </div>
            <div className='row'>
                <div class="tab-content" id="ex2-content">
                    <div
                        class="tab-pane show active" id="ex3-tabs-1" role="tabpanel" aria-labelledby="ex3-tab-1">
                        <InnerTabReact />
                    </div>
                    <div class="tab-pane" id="ex3-tabs-2" role="tabpanel" aria-labelledby="ex3-tab-2">
                        <InnerTabWordpress />
                    </div>
                    <div class="tab-pane" id="ex3-tabs-3" role="tabpanel" aria-labelledby="ex3-tab-3">
                        <PortfolioInnerShopify />
                    </div>
                </div>

            </div>
            {/* ----first-pills end--- */}
        </>
    )
}

export default WesiteTamplate
