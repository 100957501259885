import React, { useState } from 'react';
import './PortfolioTabs.scss';
import portfolio1 from "../../../Assests/Img/portfolio1.png";
import portfolio2 from "../../../Assests/Img/portfolio2.png";
import portfolio3 from "../../../Assests/Img/portfolio3.png";
import portfolio4 from "../../../Assests/Img/portfolio4.png";
import portfolio5 from "../../../Assests/Img/portfolio5.png";
import portfolio6 from "../../../Assests/Img/portfolio6.png";
const WebsiteDevelopment = () => {
    const [activeModal, setActiveModal] = useState(null);

    const toggleModal = (modalId) => {
        if (activeModal === modalId) {
            setActiveModal(null);
        } else {
            setActiveModal(modalId);
        }
    };

    const modals = [
        { id: 'modal1', src: portfolio1, imgSrc: portfolio1, title: "portfolio1" },
        { id: 'modal2', src: portfolio2, imgSrc: portfolio2, title: "portfolio2" },
        { id: 'modal3', src: portfolio3, imgSrc: portfolio3, title: "portfolio3" },
        { id: 'modal4', src: portfolio4, imgSrc: portfolio4, title: "portfolio4" },
        { id: 'modal5', src: portfolio5, imgSrc: portfolio5, title: "portfolio5" },
        { id: 'modal6', src: portfolio6, imgSrc: portfolio6, title: "portfolio6" },
    ];
    return (
        <div id='website-development'>
            <div className='top-spacing row'>
                <div className='col-lg-7 col-md-12 col-sm-12'>
                    <h4 className='short-head'>OUR PORTFOLIO</h4>
                    <h1 className='main-head'>Website Templates Design</h1>
                </div>
            </div>
            <div className="row web-flex">
                {/* <div className='col-lg-6 col-md-12 col-sm-12'> */}
                {modals.map((modal, index) => (
                    <button key={index} onClick={() => toggleModal(modal.id)}>
                        <img src={modal.src} title=''></img>
                    </button>
                ))}

                {/* Overlay */}
                {activeModal && (
                    <div className="overlay" onClick={() => setActiveModal(null)}></div>
                )}

                {modals.map((modal) => (
                    <div key={modal.id} className={`modal fade ${activeModal === modal.id ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{modal.title}</h5>
                                    <button type="button" className="close" onClick={() => toggleModal(modal.id)} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <img src={modal.imgSrc} alt="Portfolio" />
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        // </div>
    );
};

export default WebsiteDevelopment;
