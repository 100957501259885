import React from 'react'
import { Routes, Route } from "react-router-dom";
import Navbar from "./Comp/Navbar/Navbar";
import Footer from './Comp/Footer/Footer';
import Home from "./Comp/Home/Home";
import About from "./Comp/About/About";
import Contact from "./Comp/Contact/Contact";
import DigitalMarketing from './Comp/DigitalMarketing/DigitalMarketing';
import WebDevelopment from './Comp/WebDevelopment/WebDevelopment';
import AppDevelopment from './Comp/AppDevelopment/AppDevelopment';
import Hiring from "./Comp/Hiring/Hiring";
import Portfolio from "./Comp/Portfolio/Portfolio";
import CaseStudyInner from "./Comp/Portfolio/CaseStudyPage/CaseStudyInner";
import RaoPlaticSurgery from './Comp/Portfolio/CaseStudyPage/RaoPlaticSurgery';
import JadeMalay from './Comp/Portfolio/CaseStudyPage/JadeMalay';
import ShamisTate from './Comp/Portfolio/CaseStudyPage/ShamisTate';
import AccentRoofingService from './Comp/Portfolio/CaseStudyPage/AccentRoofingService';
import LucknowiAndaaz from './Comp/Portfolio/CaseStudyPage/lucknowiAndaaz';
import LifeupCorporateWellness from './Comp/Portfolio/CaseStudyPage/lifeupcorporatewellness';
import LeapWide from './Comp/Portfolio/CaseStudyPage/LeapWide';
const AllRoutes = () => {
    return (
        <>
            <Navbar />
            <Routes>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
                <Route path="contact" element={<Contact />} />
                <Route path="digitalmarketing" element={<DigitalMarketing />} />
                <Route path="webdevelopment" element={<WebDevelopment />} />
                <Route path="appdevelopment" element={<AppDevelopment />} />
                <Route path="hiring" element={<Hiring />} />
                <Route path="portfolio" element={<Portfolio />} />
                <Route path="casestudyinner" element={<CaseStudyInner />} />
                <Route path="raoplaticsurgery" element={<RaoPlaticSurgery />} />
                <Route path="jademalay" element={<JadeMalay />} />
                <Route path="shamistate" element={<ShamisTate />} />
                <Route path="accentroofingservice" element={<AccentRoofingService />} />
                <Route path="lucknowiandaaz" element={<LucknowiAndaaz />} />
                <Route path="lifeupcorporatewellness" element={<LifeupCorporateWellness />} />
                <Route path="leapwide" element={<LeapWide />} />
            </Routes>
            <Footer />

        </>
    )
}

export default AllRoutes
