import React from 'react';
import "./About.scss";
import { Link } from 'react-router-dom';
import Testimonial from '../../CommonComp/Testimonial';
import Form from '../../CommonComp/Form';
import Suceed from "../../CommonComp/Suceed";
import TopBanner from '../../CommonComp/TopBanner';
import { HiArrowUpRight } from "react-icons/hi2";
import AboutBannerImg from "../../Assests/Img/AboutBanner.webp";
import Google from "../../Assests/Img/Google.webp";
import facebook from "../../Assests/Img/facebook.webp";
import bing from "../../Assests/Img/bing.webp";
import UP from "../../Assests/Img/UP.webp";
import leapwide from "../../Assests/Img/leapwide.webp";
import Cluth from "../../Assests/Img/Cluth.webp";
import Sign from "../../Assests/Img/Sign.webp";
import Rajesh from "../../Assests/Img/Rajesh.webp";
import Mission from "../../Assests/Img/Mission.webp";
import Vision from "../../Assests/Img/Vision.webp";
import first1 from "../../Assests/Img/first1.webp";
import first2 from "../../Assests/Img/first2.webp";
import first3 from "../../Assests/Img/first3.webp";
import first4 from "../../Assests/Img/first4.webp";
import first5 from "../../Assests/Img/first5.webp";
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
const About = () => {
    return (
        <>
            <div className='aboutPage' id='aboutPage'>
                {/* ABOUT-BANNER START */}
                <TopBanner
                    bannerTitle="ABOUT US"
                    bannerDesc="J&t Web Solutions Has Team Of Professionals For Understanding Your Business And Help Digitally To Optimise/grow Your Business."
                    bannerImg={AboutBannerImg}
                    bannerimageAlt="AboutBannerImg"
                    bannerimageTitle="AboutBannerImg"
                />
                {/* ABOUT-BANNER END */}
                {/* ----------------------- */}
                {/* LOGOS START */}
                <div className='container' id='logos'>
                    <div className='logosImg1'><img src={Google} alt='Google' title='Google'></img></div>
                    <div className='logosImg'><img src={facebook} alt='facebook' title='facebook'></img></div>
                    <div className='logosImg'><img src={bing} alt='bing' title='bing'></img></div>
                    <div className='logosImg'><a href='https://www.upwork.com/agencies/jntwebsolutions/' target='_blank'><img src={UP} alt='UP' title='UP'></img></a></div>
                    <div className='logosImg'><a href='https://leapwide.com/' target='_blank'><img src={leapwide} alt='leapwide' title='leapwide'></img></a></div>
                    <div className='logosImg'><img src={Cluth} alt='Cluth' title='Cluth'></img></div>
                </div>
                {/* LOGOS END */}
                {/* ----------------------- */}
                {/* OWNER START */}
                <section className='about bg-img' id='about'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12 col-sm-12 img'><img src={Rajesh} alt='Rajesh' title='Rajesh'></img></div>
                            <div className='col-lg-7 col-md-12 col-sm-12 about-text-desc'>
                                <h4 className='short-head'>MESSAGE FROM THE OWNER</h4>
                                <h1 className='main-head'>Discover Our Journey And  <span className='red-gradient'>Commitment To Excellence</span></h1>
                                <p className='text-desciption'>At J&T Web Solutions, we firmly believe that technology should empower businesses instead of holding them back. As your trusted technology partner, it is our responsibility to deeply understand your organization's needs and goals. We then develop customized solutions that break down barriers, streamline operations, and drive sustainable growth.<br></br><br></br>
                                    Since we founded J&T Web Solutions in 2012, we have helped companies across various industries leverage technology to reduce costs, boost efficiency, reach new customers, and gain a competitive edge. However, our approach goes beyond just integrating the latest solutions. We focus on partnering with our clients for long-term success, ensuring that our solutions align with their goals and contribute to their growth.</p>
                                <div className='sign'>
                                    <div className='sign-img'><img src={Sign} title='Sign' alt='Sign'></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* OWNER END */}
                {/* ---------------------------- */}
                {/* GUIDING START */}
                <section className='container top-spacing' id='guiding'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <h4 className='short-head'>GUIDING THE FUTURE</h4>
                            <h1 className='main-head'>Advancing With Purpose & Clarity</h1>
                        </div>
                    </div>
                    <div className='row guiding-spacing guiding-spacing1'>
                        <div className='col-md-12 col-sm-12 col-lg-9 left-side'>
                            <div className='left-side-inner'>
                                <h2>Our Mission</h2>
                                <p className='text-desciption'>As a professional company specializing in web designing, web development, and digital marketing, we are committed to helping our clients enhance their business growth through creative design and development that delivers high-quality solutions to create consistent competitive advantages in the global market. We adhere to certain principles when executing our mission, such as understanding the unique needs and requirements of our customers and utilizing technology to meet those needs. We are constantly seeking new opportunities to maximize business value and ensure sustainable growth. Our goal is to empower our clients to respond more quickly and intuitively to changing market dynamics, leveraging new technologies to become more agile and competitive.</p>
                            </div>
                        </div>
                        <div className='col-md-12 col-sm-12 col-lg-3 right-side bg-img'>
                            <img src={Mission} alt='Mission' title='Mission'></img>
                        </div>
                    </div>
                    <div className='row guiding-spacing'>
                        <div className='col-md-12 col-sm-12 col-lg-3 right-side bg-img'>
                            <img src={Vision} alt='Vision' title='Vision'></img>
                        </div>
                        <div className='col-md-12 col-sm-12 col-lg-9 left-side'>
                            <div className='left-side-inner'>
                                <h2>Our Vision</h2>
                                <p className='text-desciption'>Our company’s vision is to establish itself as a trusted leader in the IT industry by delivering unparalleled services, solutions, and support to help our clients grow. We strive to realize this vision through innovative programs that ensure the timely completion of all projects while fostering lasting relationships with our clients. At our core, we believe in seizing new opportunities that maximize business value, facilitate sustainable growth, and leverage our core competencies. We are committed to meeting the expectations of our customers, employees, and partners and to expanding our reach globally, bringing the benefits of IT to improve the quality of our clients’ products and services. We aim to foster a culture of openness, trust, teamwork, simplicity, and innovation, where integrity, commitment, excellence, and client satisfaction are our guiding principles. </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* GUIDING END */}
                {/* ---------------------------- */}
                {/* JOURNEY START */}
                <section className='bg-img top-spacing' id='journey'>
                    <div className='container ' >
                        <div className='row'>
                            <div className='col-md-10'>
                                <h1 className='main-head'>Our Journey: Evolution and Milestones</h1>
                            </div>
                        </div>
                        <div className='journey-flex'>
                            <div className='row1'>
                                <img src={first1} alt='first1' title='first1'></img>
                                <div className='borders'></div>
                                <h3>4,May 2012</h3>
                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                            <div className='row1 rowReverse'>
                                <h3>17, Feb 2016</h3>
                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <div className='borders'></div>
                                <img src={first2} alt='first2' title='first2'></img>
                            </div>
                            <div className='row1'>
                                <img src={first3} alt='first3' title='first3'></img>
                                <div className='borders'></div>
                                <h3>23, Nov 2018</h3>
                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                            <div className='row1 rowReverse'>
                                <h3>20, June 2022</h3>
                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                                <div className='borders'></div>
                                <img src={first4} alt='first4' title='first4'></img>
                            </div>
                            <div className='row1'>
                                <img src={first5} alt='first5' title='first5'></img>
                                <div className='borders'></div>
                                <h3>Present</h3>
                                <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* JOURNEY END */}
                {/* ---------------------------- */}
                {/* TEAM MEMEBER START */}
                <section className='team-member top-spacing container' id='team-member'>
                    <div className='row'>
                        <div className='col-md-8'>
                            <h4 className='short-head'>team member</h4>
                            <h1 className='main-head'>great minds behind our work</h1>
                        </div>
                    </div>
                    <div className='row team-member-all-box'>
                        <div className='col-sm-6 col-xs-12 col-md-6 col-lg-3 team-member-box1'>
                            <div className='team-member-box'>
                                <img src={first1} alt='first1' title='first1'></img>
                                <div className='team-member-content'>
                                    <span>
                                        <h3>John Doe</h3>
                                        <h6>web designer</h6>
                                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12 col-md-6 col-lg-3 team-member-box2'>
                            <div className='team-member-box'>
                                <img src={first1} alt='first1' title='first1'></img>
                                <div className='team-member-content'>
                                    <span>
                                        <h3>John Doe</h3>
                                        <h6>web designer</h6>
                                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12 col-md-6 col-lg-3 team-member-box3'>
                            <div className='team-member-box'>
                                <img src={first1} alt='first1' title='first1'></img>
                                <div className='team-member-content'>
                                    <span>
                                        <h3>John Doe</h3>
                                        <h6>web designer</h6>
                                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6 col-xs-12 col-md-6 col-lg-3 team-member-box4'>
                            <div className='team-member-box'>
                                <img src={first1} alt='first1' title='first1'></img>
                                <div className='team-member-content'>
                                    <span>
                                        <h3>John Doe</h3>
                                        <h6>web designer</h6>
                                        <p className='text-desciption'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* TEAM MEMEBER END */}
                {/* --------------------------------- */}
                {/* TESTIMONIAL START */}
                <Testimonial />
                {/* TESTIMONIAL END */}
                {/* ----------------------------------------- */}
                {/* DIGITAL DREAMS START */}
                <section className='container top-spacing' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>we turn into digital dreams into realty</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Get a FREE Instant Consultation. Let's Start With Your Business Idea Today!</p>
                            <div className='btn'>
                                <Link to='/contact'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                {/* DIGITAL DREAMS END */}
                {/* ----------------------------------------- */}
                {/* GET IN TOUCH START */}
                <Form />
                {/* GET IN TOUCH END */}
                {/* -------------------------------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default About
