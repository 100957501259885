import CaseStudyInner from './CaseStudyInner';
import banner from "../../../Assests/Img/ShamisTate.webp";
import ShamisTate1 from "../../../Assests/Img/ShamisTate1.webp";
import ShamisTate2 from "../../../Assests/Img/ShamisTate2.webp";
import ShamisTate3 from "../../../Assests/Img/ShamisTate3.webp";
import ShamisTateMockup from "../../../Assests/Img/ShamisTateMockup.webp";
const ShamisTate = () => {
    return (
        <CaseStudyInner
            bannerSrc={banner}
            altText="banner"
            titleText="banner"
            heading="Shamis Tate"
            description="Shamis Tate is a distinguished figure in Functional Medicine, excelling in the treatment of chronic metabolic ailments such as Type 2 Diabetes and hypothyroidism."
            buttonText="learn more"
            link="https://shamistate.com/"

            // case-information

            technology="wordpress"
            project="landing page"
            customer="shamis tate"
            timeduration="1 day"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={ShamisTate1}
            personalize2={ShamisTate2}
            personalize1alt="ShamisTate1"
            personalize2alt="ShamisTate1"
            personalize1title="ShamisTate2"
            personalize2title="ShamisTate2"

            // challenges
            challengesimg={ShamisTate3}
            challengesalt="ShamisTate3"
            challengestitle="ShamisTate3"
            challengesdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="Medical Departments"
            enhancingdesc="Shamis Tate is a distinguished figure in Functional Medicine, excelling in the treatment of chronic metabolic ailments such as Type 2 Diabetes and hypothyroidism. Armed with a Master of Science in Nursing and board certification as a Family Nurse Practitioner, Shamis is a beacon of innovative healthcare.
            Her commitment to personalized, root-cause interventions epitomizes her visionary approach. With a fervent dedication to holistic healing, Shamis Tate is steadfast in her mission to guide individuals toward their pinnacle of well-being, empowering them on their path to optimal health."
            list1="adobe photoshop"
            list2="wordpress"
            list3="google fonts"
            enhancingimg={ShamisTateMockup}
            enhancingtitle="ShamisTateMockup"
            enhancingalt="ShamisTateMockup"

            // work-we-do
            workimg={ShamisTate2}
            workalt="ShamisTate2"
            worktitle="ShamisTate2"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default ShamisTate;



