import CaseStudyInner from './CaseStudyInner';
import jademaley1 from "../../../Assests/Img/jademalay1.webp";
import jademaley2 from "../../../Assests/Img/jademalay2.webp";
import jademaley3 from "../../../Assests/Img/jademaley3.webp";
import jademaley4 from "../../../Assests/Img/jademaley4.webp";
import jademaleyMockup from "../../../Assests/Img/jademaleyMockup.webp";
const JadeMalay = () => {
    return (
        <CaseStudyInner
            bannerSrc={jademaley1}
            altText="jademaley1"
            titleText="jademaley1"
            heading="Jade Malay"
            description="Welcome to the world of Jade Malay, a celebrated professional writer known for her spellbinding storytelling and impeccable attention to detail. With an impressive portfolio that crosses multiple genres, Jade has become a leading voice in the literary community. "
            buttonText="learn more"
            link="https://jademalay.com/"

            // case-information

            technology="wordpress"
            project="landing website"
            customer="jade malay"
            timeduration="1 day"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={jademaley2}
            personalize2={jademaley3}
            personalize1alt="jademaley2"
            personalize2alt="jademaley2"
            personalize1title="jademaley3"
            personalize2title="jademaley3"

            // challenges
            challengesimg={jademaley4}
            challengesalt="jademaley4"
            challengestitle="jademaley4"
            challengesdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="Early Passion for Storytelling"
            enhancingdesc="From an early age, Jade Malay had a deep passion for stories. Immersed in books and captivated by the worlds they held, Jade found a creative sanctuary that would shape her future profession. This early love for storytelling laid a strong foundation for her literary career, fueling her dedication and helping her craft a unique narrative voice. Today, readers worldwide are captivated by Jade Malay's work, a testament to the joy and fulfillment she discovered in those formative years."
            list1="adobe photoshop"
            list2="wordpress"
            list3="google fonts"
            enhancingimg={jademaleyMockup}
            enhancingtitle="jademaleyMockup"
            enhancingalt="jademaleyMockup"

            // work-we-do
            workimg={jademaley1}
            workalt="jademaley1"
            worktitle="jademaley1"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default JadeMalay;