import React from 'react';
import { HiArrowUpRight } from "react-icons/hi2";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { MdPhoneInTalk } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import icon1 from "../../Assests/Img/icon1.webp";
import icon2 from "../../Assests/Img/icon2.webp";
import { Link } from 'react-router-dom';
import "./Footer.scss";
const Footer = () => {
    return (
        <>
            <footer className='bg-img'>
                <div className='container' id='footer'>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6 col-sm-6 newsletter'>
                            <h1 className='main-head'>Have An Awesome Project in mind?</h1>
                            <div className='input'>
                                <input type='text' placeholder='enter your email'></input>
                                <button><HiArrowUpRight /></button>
                            </div>
                            <p className='text-desciption'>Subscribe to our newsletter</p>
                            <div className='social-icons'>
                                <a href='https://www.facebook.com/jntwebsolutions/' target='_blank'><FaFacebookF /></a>
                                <a href='https://www.instagram.com/jntwebsolutions/?igshid=YmMyMTA2M2Y%3D' target='_blank'><FaInstagram /></a>
                                <a href='https://x.com/jntwebsolutions?lang=en' target='_blank'><FaXTwitter /></a>
                                <a href='https://www.linkedin.com/company/j-t-web-solutions-pvt-ltd' target='_blank'> <FaLinkedinIn /></a>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-6 all-links padding-remove'>
                            <h4>company</h4>
                            <ul>
                                <li><Link to="/">home</Link></li>
                                <li> <Link to="/about">About</Link></li>
                                <li><Link to="/portfolio">portfolio</Link></li>
                                <li><Link to="/hiring">hiring</Link></li>
                                <li><Link to="/contact">contact us</Link></li>
                            </ul>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 all-links'>
                            <h4>solutions</h4>
                            <ul>
                                <li>Digital Marketing</li>
                                <li>Web Development</li>
                                <li>Pay-Per-Click</li>
                                <li>Social Media Optimization</li>
                                <li>Online Repution Management</li>
                                <li>Search Engine Optimization</li>
                                <li>Web & Graphics Designs</li>
                            </ul>
                        </div>
                        <div className='col-lg-3 col-md-6 col-sm-6 all-links'>
                            <h4>get in touch</h4>
                            <div className='all-links-icon'>
                                <span><MdPhoneInTalk /></span>
                                <span>
                                    <ul>
                                        <li><a href='tel:+91 623 992 1226'>+91 623 992 1226</a></li>
                                        <li><a href='tel:+91 623 992 1229'>+91 623 992 1229</a></li>
                                    </ul>
                                </span>
                            </div>
                            <div className='all-links-icon'>
                                <span><MdEmail /></span>
                                <span>
                                    <ul>
                                        <li><a href='mailto:hr@jntwebsolutions.com' target='_blank'>hr@jntwebsolutions.com</a></li>
                                    </ul>
                                </span>
                            </div>
                            <div className='all-links-icon'>
                                <span><FaMapMarkerAlt /></span>
                                <span>
                                    <ul>
                                        <li><a href='https://maps.app.goo.gl/hR713azbitT8RNcW9' target='_blank'>
                                            Plot No. D-160, Phase 8, Industrial Area, Sector 73, Sahibzada Ajit Singh Nagar, Punjab 160055</a></li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row crafting'>
                        <div className='col-md-4'>
                            <div className='imp-links'>
                                <img src={icon1} alt='icon1' title='icon1'></img>
                                <img src={icon2} alt='icon2' title='icon2' className='icon2'></img>
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='copyright'>
                        <div className='row'>
                            <div className='col-md-6'><p>terms and conditions |<a href=''>privacy policy</a>|</p></div>
                            <div className='col-md-6 copyright-txt'><p>© Copyright 2024 | J&T Web Solutions Pvt. Ltd. | All Rights Reserved </p></div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
