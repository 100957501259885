import CaseStudyInner from './CaseStudyInner';
import AccentRoofingServiceImg from "../../../Assests/Img/AccentRoofingService.webp";
import AccentRoofingService1 from "../../../Assests/Img/AccentRoofingService1.webp";
import AccentRoofingService2 from "../../../Assests/Img/AccentRoofingService2.webp";
import AccentRoofingService3 from "../../../Assests/Img/AccentRoofingService3.webp";
import AccentRoofingService4 from "../../../Assests/Img/AccentRoofingService4.webp";
import AccentRoofingServiceMockup from "../../../Assests/Img/AccentRoofingServiceMockup.webp";
import jademaleyMockup from "../../../Assests/Img/jademaleyMockup.webp";
const AccentRoofingService = () => {
    return (
        <CaseStudyInner
            bannerSrc={AccentRoofingServiceImg}
            altText="Accent Roofing Service"
            titleText="Accent Roofing Service"
            heading="Accent Roofing Service"
            description="Expertise comes standard with all our roofing services, but to truly know about Accent Roofing is to understand our deeply held commitment to our clients. Our mission is to provide clients with their best contractor experience ever."
            buttonText="learn more"
            link="https://www.accentroofingservice.com/"

            // case-information

            technology="wordpress"
            project="revamp website"
            customer="thomas eberhard"
            timeduration="10 days"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={AccentRoofingService1}
            personalize2={AccentRoofingService2}
            personalize1alt="AccentRoofingService1"
            personalize2alt="AccentRoofingService1"
            personalize1title="AccentRoofingService2"
            personalize2title="AccentRoofingService2"

            // challenges
            challengesimg={AccentRoofingService4}
            challengesalt="AccentRoofingService4"
            challengestitle="AccentRoofingService4"
            challengesdesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="We're Dedicated to Our Clients and Our Craft"
            enhancingdesc="We thrive on making Accent Roofing Service the most complete and trusted roofing company in Georgia. We come highly recommended by insurance agencies, consumer agencies, and many satisfied clients who consider us to be the best, most reliable and most competitive roofing contractor in Georgia."
            list1="adobe photoshop"
            list2="wordpress"
            list3="google fonts"
            enhancingimg={AccentRoofingServiceMockup}
            enhancingtitle="AccentRoofingServiceMockup"
            enhancingalt="AccentRoofingServiceMockup"

            // work-we-do
            workimg={AccentRoofingService3}
            workalt="AccentRoofingService3"
            worktitle="AccentRoofingService3"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default AccentRoofingService;