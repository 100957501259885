import React from 'react';

const TopBanner = (
    {
        bannerTitle,
        bannerDesc,
        bannerImg,
        bannerimageAlt,
        bannerimageTitle,
    }
) => {
    return (
        <div className='container about-banner' id='about-banner'>
            <div className='row'>
                <div className='col-lg-5 col-md-5 left-text'>
                    <h1 className='main-head banner-head'>{bannerTitle}</h1>
                    <p className='text-description banner-desciption'>{bannerDesc}</p>
                </div>
                <div className='col-lg-7 col-md-7 img'>
                    <img src={bannerImg} alt={bannerimageAlt} title={bannerimageTitle} />
                </div>
            </div>
        </div>
    );
};

export default TopBanner;
