import React from 'react';
import "./WebDevelopment.scss";
import { HiArrowUpRight } from "react-icons/hi2";
import Suceed from '../../CommonComp/Suceed';
import Form from '../../CommonComp/Form';
import TopBanner from '../../CommonComp/TopBanner';
import ShapeSmall from "../../Assests/Img/ShapeSmall.webp";
import helpandsupport from "../../Assests/Img/helpabdsupport.webp";
import banner from "../../Assests/Img/digitalmarketing-banner.webp";
import works from "../../Assests/Img/works.webp";
import service1 from "../../Assests/Img/service1.webp";
import service2 from "../../Assests/Img/service2.webp";
import service3 from "../../Assests/Img/service3.webp";
import service4 from "../../Assests/Img/service4.webp";
import service5 from "../../Assests/Img/service5.webp";
import service6 from "../../Assests/Img/service6.webp";
const WebDevelopment = () => {
    // services
    const services = [
        {
            imgSrc: service1,
            alt: 'service1',
            title: 'WordPress Development',
            items: [
                'Installation & Setup',
                'WordPress Migration',
                'Theme And Template',
                'CMS Development',
                'Custom WordPress Theme'
            ]
        },
        {
            imgSrc: service2,
            alt: 'service2',
            title: 'Web Designing',
            items: [
                'Website Redesign',
                'Graphic Design',
                'Landing Page',
                'Logo Design',
                'Responsive Web Design'
            ]
        },
        {
            imgSrc: service3,
            alt: 'service3',
            title: 'ReactJS Development',
            items: [
                'ReactJS QA And Testing',
                'ReactJS UI Development',
                'ReactJS Plug-in Development',
                'ReactJS Application Development',
                'ReactJS Support And Maintenance'
            ]
        },
        {
            imgSrc: service4,
            alt: 'service4',
            title: 'Frame Work Development',
            items: [
                'Laravel',
                'PrestaShop',
                'Yii Framework',
                'NodeJS Framework',
                'Angular JS Framework'
            ]
        },
        {
            imgSrc: service5,
            alt: 'service5',
            title: 'API Development',
            items: [
                'API Consulting',
                'Payment API Integration',
                'Custom API Development',
                'Third-Party API Integration',
                'API Testing Automation'
            ]
        },
        {
            imgSrc: service6,
            alt: 'service6',
            title: 'E-Commerce',
            items: [
                'Discovery And Research',
                'Optimization And Support',
                'Seamless User Experience',
                'E-Commerce Development',
                'E-Commerce Website Design'
            ]
        }
    ];
    // workProcessSteps
    const workProcessSteps = [
        {
            id: 1,
            title: 'Super Skilled Developers',
            description: 'Get in touch with us to discuss your project requirements and objectives. We\'ll listen closely to understand your vision and goals.'
        },
        {
            id: 2,
            title: 'Design Focused',
            description: 'Based on our consultation, we\'ll provide a detailed proposal outlining the scope of work, timeline, and budget.'
        },
        {
            id: 3,
            title: 'Superior Quality',
            description: 'With the agreement in place, we\'ll schedule a kickoff meeting to introduce you to our team and discuss the project plan in detail.'
        },
        {
            id: 4,
            title: 'Fully Customized',
            description: 'Our team will start working on your project, keeping you updated with regular progress reports.'
        },
        {
            id: 5,
            title: 'Proven Track Record',
            description: 'We value your feedback and will incorporate it into our work. Upon completion, we\'ll deliver the final product to you.'
        },
        {
            id: 6,
            title: '24*7 Customer Service',
            description: 'We also provide post-launch support to ensure everything runs smoothly. No limit on the number of revisions.'
        }
    ];
    // faqItems
    const faqItems = [
        {
            id: 'collapseOne',
            title: 'Can I get Quick SEO Results?',
            content: 'Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide',
        },
        {
            id: 'collapseTwo',
            title: 'How can social media marketing help my business?',
            content: 'Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide',
        },
        {
            id: 'collapseThree',
            title: 'Why is digital marketing important?',
            content: 'Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide',
        },
        {
            id: 'collapseFour',
            title: 'What are the benefits of SEO for digital marketing?',
            content: 'Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide',
        },
        {
            id: 'collapseFive',
            title: 'Is digital marketing suitable for all businesses?',
            content: 'Some SEO companies in India promise to offer quick results for SEO. But you should never forget that SEO takes time; it is the only black hat SEO strategy (like Content Automation, Doorway Pages, Keyword Stuffing, and Bad Backlinks) that will provide',
        }
    ];

    return (
        <>
            <div className='webDevelopment' id='webDevelopment'>
                {/* BANNER START */}
                <TopBanner
                    bannerTitle="WEB DEVELOPMENT"
                    bannerDesc="Our team of Web Developers are highly skilled and capable of developing any kind of website, ensuring that it aligns with our clients' specific goals and requirements."
                    bannerImg={banner}
                    bannerimageAlt="webdevportfoliobanner"
                    bannerimageTitle="webdevportfoliobanner"
                />
                {/* BANNER END */}
                {/* ----------------------------- */}
                {/* DIGITAL-MARKETING START */}
                <div className='digital-marketing-services' id='digital-marketing-services'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>OUR SERVICES</h4>
                                <h1 className='main-head'>Web Development Services We Offer</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>At J&T Web Solutions, we specialize in crafting customized web design solutions.</p>
                            </div>
                        </div>
                        <div className='row digital-marketing-services-spacing'>
                            {services.map((service, index) => (
                                <div className='col-lg-4' key={index}>
                                    <div className='icon-box'>
                                        <img src={service.imgSrc} alt={service.alt} title={service.title} />
                                        <h3 className='icon-box-head'>{service.title}</h3>
                                        <ul>
                                            {service.items.map((item, itemIndex) => (
                                                <li key={itemIndex}>- {item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* DIGITAL-MARKETING END */}
                {/* --------------------------- */}
                {/* HOW TO WORKS START */}
                <div className='webDevelopment-Works container top-spacing' id='webDevelopment-Works'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img src={works} alt='works' title='works'></img>
                        </div>
                        <div className='col-md-6'>
                            <h4 className='short-head'>HOW IT WORKS</h4>
                            <h1 className='main-head'>Efficiency in Motion Navigating the Working Process</h1>
                            <div className='icon-box'>
                                <h4 className='number'>01</h4>
                                <h3>Discover & Analysis</h3>
                                <p className='text-description'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                                </p>
                            </div>
                            <div className='icon-box'>
                                <h4 className='number'>02</h4>
                                <h3>Strategy Development</h3>
                                <p className='text-description'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                                </p>
                            </div>
                            <div className='icon-box'>
                                <h4 className='number'>03</h4>
                                <h3>Launch & Execution</h3>
                                <p className='text-description'>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* HOW TO WORKS END */}
                {/* -------------------------- */}
                {/* STEP BOX START */}
                <div className='workProcess bg-img top-spacing' id='workProcess'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-sm-12'>
                                <h4 className='short-head'>LET US DOIT FOR YOU</h4>
                                <h1 className='main-head'>our work process</h1>
                            </div>
                            <div className='col-lg-5 col-md-12 col-sm-12 services-txt'>
                                <p className='text-desc'>We Offer A Highly Effective Seo Service That Helps You Rank Higher On Search Engines.</p>
                            </div>
                        </div>
                        <div className='row display-flex'>
                            {workProcessSteps.slice(0, 3).map((step, index) => (
                                <div
                                    key={step.id}
                                    className={`col-lg-4 col-md-12 col-sm-12 ${index === 0 ? 'left-box' : index === 1 ? 'middle-box' : 'right-box'}`}
                                >
                                    <div className='steps-box'>
                                        <div className='count'>
                                            <span>{step.id}</span>
                                        </div>
                                        <h4 className='icon-box-head'>{step.title}</h4>
                                        <p className='text-desciption'>{step.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='row display-flex'>
                            {workProcessSteps.slice(3).map((step, index) => (
                                <div
                                    key={step.id}
                                    className={`col-lg-4 col-md-12 col-sm-12 ${index === 0 ? 'left-box' : index === 1 ? 'middle-box' : 'right-box'}`}
                                >
                                    <div className='steps-box'>
                                        <div className='count'>
                                            <span>{step.id}</span>
                                        </div>
                                        <h4 className='icon-box-head'>{step.title}</h4>
                                        <p className='text-desciption'>{step.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* STEP BOX END */}
                {/* -------------------------- */}
                {/* HELP AND SUPPORT START */}
                <div className='advantages container top-spacing help-and-support' id='advantages'>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12'>
                            <h4 className='short-head'>HELP & SUPPORT</h4>
                            <h1 className='main-head'>Frequently Asked Questions</h1>
                            <div className="accordion" id="accordionExample">
                                {faqItems.map((item, index) => (
                                    <div className="accordion-item" key={item.id}>
                                        <h2 className="accordion-header" id={`heading${index + 1}`}>
                                            <button
                                                className={`accordion-button ${index === 0 ? '' : 'collapsed'}`}
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target={`#${item.id}`}
                                                aria-expanded={index === 0 ? 'true' : 'false'}
                                                aria-controls={item.id}
                                            >
                                                {item.title}
                                            </button>
                                        </h2>
                                        <div
                                            id={item.id}
                                            className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                            aria-labelledby={`heading${index + 1}`}
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                <p>{item.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12'>
                            <img src={helpandsupport} alt='Help and Support' title='Help and Support' />
                        </div>
                    </div>
                </div>
                {/* HELP AND SUPPORT END */}
                {/* --------------------------- */}
                {/* BEAUTIFUL WEBSITEE START */}
                <section className='container top-spacing discussion' id='digital-dreams'>
                    <div className='row digital-dreams-inner'>
                        <div className='col-md-5'>
                            <h1 className='main-head'>Create a Beautiful Website. It's Easier Than You Think</h1>
                        </div>
                        <div className='col-md-2 img'>
                            <img src={ShapeSmall} alt='ShapeSmall' title='ShapeSmall'></img>
                        </div>
                        <div className='col-md-5'>
                            <p className='text-desciption'>Get a FREE Instant Consultation. Let's Start With Your Business Idea Today!</p>
                            <div className='btn'>
                                <a href='#'>
                                    <button>learn more <span><HiArrowUpRight /></span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                {/* BETTER DISCUSIION END */}
                {/* --------------------------- */}
                <Form />
                {/* ----------------------- */}
                {/* SUCEED START */}
                <Suceed />
                {/* SUCEED END */}
            </div>
        </>
    )
}

export default WebDevelopment
