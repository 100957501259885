import CaseStudyInner from './CaseStudyInner';
import lifeupcorporatewellnessBanner from "../../../Assests/Img/lifeupcorporatewellnessMain.webp";
import lifeupcorporate1 from "../../../Assests/Img/lifeupcorporatewellness1.webp";
import lifeupcorporate2 from "../../../Assests/Img/lifeupcorporate2.webp";
import lifeupcorporate3 from "../../../Assests/Img/lifeupcorporate3.webp";
import lifeupcorporateMockup from "../../../Assests/Img/lifeupcorporateMockup.webp";
import lifeupcorporateMain from "../../../Assests/Img/lifeupcorporatewellnessMain.webp"
const lifeupcorporatewellness = () => {
    return (
        <CaseStudyInner
            bannerSrc={lifeupcorporatewellnessBanner}
            altText="lifeupcorporatewellness"
            titleText="lifeupcorporatewellness"
            heading="LifeupCorporate Wellness"
            description="LifeUP Corporate Wellness designs corporate wellness programs that create the 'a-ha!' moments that transform ordinary lives into extraordinary lifestyles."
            buttonText="learn more"
            link="https://lifeupcorporatewellness.com/"

            // case-information

            technology="wordpress"
            project="revamp website"
            customer="Derek Opperman"
            timeduration="20 Days"

            // personalize
            personalizedesc1="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc2="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalizedesc3="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            personalize1={lifeupcorporate1}
            personalize2={lifeupcorporate2}
            personalize1alt="lifeupcorporate1"
            personalize2alt="lifeupcorporate1"
            personalize1title="lifeupcorporate2"
            personalize2title="lifeupcorporate2"

            // challenges
            challengesimg={lifeupcorporate3}
            challengesalt="lifeupcorporate3"
            challengestitle="lifeupcorporate3"
            challengesdesc="Get the best corporate health & wellness coach in Los Angeles and enables your organization to…"
            accordion1head="Accordion Item #1"
            accordion1desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion2head="Accordion Item #2"
            accordion2desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            accordion3head="Accordion Item #2"
            accordion3desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."

            // enhancing-beauty
            enhancinghead="A Unique Approach to Corporate Wellness"
            enhancingdesc="Derek Opperman is a certified Corporate Wellness coach in LA across multiple health disciplines, from fitness training to nutrition design, and integrative lifestyle planning. He’s also certified as a Corporate Wellness Specialist and is able to speak about the importance of health at a company-wide, cultural level."
            list1="adobe photoshop"
            list2="react"
            list3="google fonts"
            enhancingimg={lifeupcorporateMockup}
            enhancingtitle="lifeupcorporateMockup"
            enhancingalt="lifeupcorporateMockup"

            // work-we-do
            workimg={lifeupcorporateMain}
            workalt="lifeupcorporateMain"
            worktitle="lifeupcorporateMain"
            worklist1="adobe photoshop"
            worklist2="wordpress"
            worklist3="google fonts"
            worklist4="adobe photoshop"
            worklist5="wordpress"
            worklist6="google fonts"
        />
    );
};

export default lifeupcorporatewellness;





